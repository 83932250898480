import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Text } from '@printi/printi-components';
import { footerLinksLoadRequest } from '@store/footerLinks/actions';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import * as S from './Footer.styles';
import IconSocial from './icons/IconSocial';
const ModalCookieConsent = dynamic(() => import('../ModalCookieConsent'));

const FooterLinks = () => {
  const [showModalCookieConsent, setShowModalCookieConsent] = useState(false);
  const dispatch = useDispatch();
  const { footerLinks } = useSelector((state) => state.footerLinks);

  useEffect(() => {
    if (!footerLinks) {
      dispatch(footerLinksLoadRequest());
    }
  }, [footerLinks, dispatch]);

  const toggleShowCookieConsentModal = useCallback(() => {
    setShowModalCookieConsent((prevState) => !prevState);
  }, []);

  return (
    <>
      <S.FooterLinks>
        <Row>
          <Col xs={12} lg={4}>
            <S.LogoFooter id="Printi-Logo-footer" />

            <S.NavSocialLink>
              <S.SocialLink
                type="facebook"
                href="https://www.facebook.com/PrintiBR/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <IconSocial name="facebook" />
              </S.SocialLink>
              <S.SocialLink
                type="twitter"
                href="https://twitter.com/Printi"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <IconSocial name="twitter" />
              </S.SocialLink>
              <S.SocialLink
                type="linkedin"
                href="https://www.linkedin.com/company/printi"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Linkedin"
              >
                <IconSocial name="linkedin" />
              </S.SocialLink>
              <S.SocialLink
                type="instagram"
                href="https://www.instagram.com/printibr/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <IconSocial name="instagram" />
              </S.SocialLink>
              <S.SocialLink
                type="pinterest"
                href="https://br.pinterest.com/printi/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Pinterest"
              >
                <IconSocial name="pinterest" />
              </S.SocialLink>
              <S.SocialLink
                type="medium"
                href="https://medium.com/printi-tech"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Medium"
              >
                <IconSocial name="medium" />
              </S.SocialLink>
            </S.NavSocialLink>

            <Text size="sm" margin="0 0 1rem">
              © {new Date().getFullYear()} - Printi. Todos os direitos reservados
            </Text>
          </Col>
          <Col xs={12} lg={8}>
            <S.LinkList>
              {footerLinks &&
                footerLinks.map(({ slug, position, title, footer_link, target }) => {
                  if (target === '_blank') {
                    return (
                      <S.LinkFooter
                        key={slug || position + title}
                        href={footer_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {title}
                      </S.LinkFooter>
                    );
                  }

                  return (
                    <Link key={slug || position + title} href={footer_link} to={footer_link} passHref>
                      <S.LinkFooter>{title}</S.LinkFooter>
                    </Link>
                  );
                })}
              <S.CookieSettingsButton onClick={toggleShowCookieConsentModal}>
                Política de Cookies
              </S.CookieSettingsButton>
            </S.LinkList>
          </Col>
        </Row>
      </S.FooterLinks>
      {showModalCookieConsent && <ModalCookieConsent hideBanner close={toggleShowCookieConsentModal} />}
    </>
  );
};

export default memo(FooterLinks);
