import React from 'react';

import { QUICK_ACCESS } from '../constants';
import { searchClient } from '../searchClient';
import { cx, intersperse } from '../utils';

export const quickAccessPlugin = {
  async getSources({ query }) {
    if (query.length > 1) {
      return [];
    }

    const results = await searchClient.collections(QUICK_ACCESS).documents().search({
      q: '',
      query_by: 'template',
      // highlight_full_fields: 'template',
      // include_fields: 'name',
      sort_by: 'sortable_id:ASC',
      filter_by: 'status:published',
      per_page: 4,
    });

    return [
      {
        sourceId: 'quickAccessPlugin',
        getItems() {
          return results.hits;
        },
        templates: {
          header({ Fragment }) {
            return (
              <Fragment>
                <h4 className="aa-SourceHeaderTitle">Acesso rápido</h4>
              </Fragment>
            );
          },
          item({ item }) {
            return <QuickAccessItem hit={item} />;
          },
        },
      },
    ];
  },
};

function QuickAccessItem({ hit }) {
  return (
    <a
      href={hit.document?.href}
      className={cx('aa-ItemLink aa-QuickAccessItem', `aa-QuickAccessItem--${hit.document?.template}`)}
    >
      <div className="aa-ItemContent">
        {hit.document?.image && (
          <div className="aa-ItemPicture">
            <img src={hit.document?.image} alt={hit.document?.title} />
          </div>
        )}

        <div className="aa-ItemContentBody">
          {hit.document?.date && <div className="aa-ItemContentDate">{hit.document?.date}</div>}
          <div className="aa-ItemContentTitle">{intersperse(hit.document?.title.split('\n'), <br />)}</div>
          {hit.document?.subtitle && (
            <div className="aa-ItemContentSubTitle">{intersperse(hit.document?.subtitle.split('\n'), <br />)}</div>
          )}

          {hit.document?.links && (
            <ul>
              {hit.document?.links.map((link) => (
                <li key={link.text}>
                  <a href={link.href}>{link.text}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </a>
  );
}
