/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';

// eslint-disable-next-line react/prop-types
const HubspotWrapper = ({ children }) => {
  const handleHideChat = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove({ resetWidget: true });
    }
  };

  useEffect(() => {
    if (window) {
      handleHideChat();
    }
  });

  return <>{children}</>;
};

export default HubspotWrapper;
