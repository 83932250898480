import React, { memo } from 'react';

const IconNewsletter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.95 55.18">
    <path
      fill="#cbcfd4"
      d="M92.78 14.53c4.49 0 7.69 3.64 7.69 8.14v23.39a8.12 8.12 0 0 1-8.12 8.12H49.47a9 9 0 0 1-9-9V22.67a8.33 8.33 0 0 1 8.36-8.14"
    ></path>
    <path
      fill="#151617"
      d="M93.22 55.18H49.47c-5.62 0-10-4-10-9.12V22.67a9.69 9.69 0 0 1 9.78-9.64 1.45 1.45 0 0 1 1.32.85 1.51 1.51 0 0 1-1.36 2.15 6.69 6.69 0 0 0-6.73 6.64v23.39c0 3.83 3.57 6.12 7 6.12h43.73a5.92 5.92 0 0 0 6.25-6.12V22.67a6.47 6.47 0 0 0-6.44-6.64 1.51 1.51 0 0 1-1.36-2.15 1.44 1.44 0 0 1 1.31-.85 9.44 9.44 0 0 1 9.5 9.64v23.39a8.89 8.89 0 0 1-9.25 9.12z"
    ></path>
    <path
      fill="#151617"
      d="M71.32 37.39a9.59 9.59 0 0 1-4.67-1.2l-24.57-15.3a1.503 1.503 0 1 1 1.59-2.55l24.5 15.26a6.65 6.65 0 0 0 6.46-.07l23.9-15.18a1.5 1.5 0 1 1 1.61 2.53l-24 15.22a9.79 9.79 0 0 1-4.82 1.29z"
    ></path>
    <path
      fill="#fe0060"
      d="M73.47 10.51c-1.12 0-2.15 1.39-3 2-.83-.62-1.86-2-3-2a5 5 0 0 0-5 5c0 2.76 7.14 10.91 8 10.29.83.62 8-7.53 8-10.29a5 5 0 0 0-5-5z"
    ></path>
    <path
      fill="#151617"
      d="M70.6 27.18s-.08.08-.12.07-.08 0-.12 0c-2.45 0-9.36-8.71-9.36-11.81a6.5 6.5 0 0 1 6.5-6.49 4.57 4.57 0 0 1 3 1.5 4.56 4.56 0 0 1 3-1.49 6.51 6.51 0 0 1 6.5 6.5c0 2-2.07 4.87-3.8 7-3.84 4.78-5.16 4.72-5.6 4.72zm-3.13-15.17a3.5 3.5 0 0 0-3.49 3.5c0 1.65 4.77 7.55 6.48 8.74 1.7-1.19 6.48-7.09 6.48-8.74a3.5 3.5 0 0 0-3.5-3.5 4.68 4.68 0 0 0-1.19.93 10.51 10.51 0 0 1-.88.77 1.52 1.52 0 0 1-1.79 0 10.36 10.36 0 0 1-.89-.78 4.52 4.52 0 0 0-1.22-.92z"
    ></path>
    <path
      fill="#fe0060"
      d="M133.47 1.51c-1.12 0-2.15 1.39-3 2-.83-.62-1.86-2-3-2a5 5 0 0 0-5 5c0 2.76 7.14 10.91 8 10.29.83.62 8-7.53 8-10.29a5 5 0 0 0-5-5z"
    ></path>
    <path
      fill="#151617"
      d="M130.6 18.18s-.08.08-.12.07-.08 0-.12 0c-2.45 0-9.36-8.71-9.36-11.81a6.5 6.5 0 0 1 6.5-6.49 4.57 4.57 0 0 1 3 1.5 4.56 4.56 0 0 1 3-1.49 6.51 6.51 0 0 1 6.5 6.5c0 2-2.07 4.87-3.8 7-3.84 4.78-5.16 4.72-5.6 4.72zm-3.13-15.17a3.5 3.5 0 0 0-3.49 3.5c0 1.65 4.77 7.55 6.48 8.74 1.7-1.19 6.48-7.09 6.48-8.74a3.5 3.5 0 0 0-3.5-3.5 4.68 4.68 0 0 0-1.19.93 10.51 10.51 0 0 1-.88.77 1.52 1.52 0 0 1-1.79 0 10.36 10.36 0 0 1-.89-.78 4.52 4.52 0 0 0-1.22-.92z"
    ></path>
    <path
      fill="#fe0060"
      d="M10.47 26.06c-1 0-1.87 1.21-2.6 1.75-.73-.54-1.62-1.75-2.6-1.75a4.36 4.36 0 0 0-4.36 4.36c0 2.41 6.23 9.52 7 9 .73.54 7-6.57 7-9a4.36 4.36 0 0 0-4.44-4.36z"
    ></path>
    <path
      fill="#151617"
      d="M8.38 41.18s-.07-.09-.1-.09h-.1c-2.14 0-8.17-7.64-8.17-10.34a5.69 5.69 0 0 1 5.67-5.68 4 4 0 0 1 2.6 1.3 4 4 0 0 1 2.6-1.31 5.68 5.68 0 0 1 5.67 5.67c0 1.72-1.8 4.41-3.32 6.29-3.32 4.06-4.47 4.16-4.85 4.16zM5.67 27.62a3.05 3.05 0 0 0-3 3.05c0 1.44 4.16 6.58 5.65 7.63 1.49-1 5.65-6.18 5.65-7.63a3.06 3.06 0 0 0-3.05-3.05 4.08 4.08 0 0 0-1 .82 9.17 9.17 0 0 1-.77.68 1.32 1.32 0 0 1-1.57 0 9 9 0 0 1-.78-.68 4 4 0 0 0-1.14-.82z"
    ></path>
    <path
      fill="#fe0060"
      d="M113.76 38.44c-.78 0-1.49 1-2.07 1.4-.58-.43-1.29-1.4-2.07-1.4a3.48 3.48 0 0 0-3.48 3.48c0 1.92 5 7.6 5.55 7.16.58.43 5.55-5.24 5.55-7.16a3.48 3.48 0 0 0-3.48-3.48z"
    ></path>
    <path
      fill="#151617"
      d="M111.68 49.18s-.05.29-.08.29-.05.15-.08.15c-1.7 0-6.52-6-6.52-8.16a4.5 4.5 0 0 1 4.52-4.49 3.19 3.19 0 0 1 2.07 1.06 3.16 3.16 0 0 1 2.07-1 4.53 4.53 0 0 1 4.52 4.53c0 1.37-1.44 3.16-2.65 4.66-2.62 3.24-3.54 2.96-3.85 2.96zm-2.21-10.09a2.44 2.44 0 0 0-2.43 2.44c0 1.15 3.32 5.25 4.51 6.08 1.19-.83 4.51-4.93 4.51-6.08a2.44 2.44 0 0 0-2.44-2.43 3.26 3.26 0 0 0-.83.65 7.27 7.27 0 0 1-.62.54 1 1 0 0 1-1.24 0 7.17 7.17 0 0 1-.62-.54 3.15 3.15 0 0 0-.84-.66zM63.58 13.03H49.47c-5.62 0-10 3.86-10 9v12h3v-12c0-3.83 3.57-6 7-6h13.21zm29.64 0H78.47v3h14.75c3.68 0 6.25 2.37 6.25 6v12h3v-12c0-5.31-3.89-9-9.25-9z"
    ></path>
  </svg>
);

export default memo(IconNewsletter);
