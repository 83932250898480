import { useCallback } from 'react';

import { api } from '@utils';

const useCookieConsent = () => {
  const storeCookieConsent = useCallback(async (payload) => {
    await api
      .post('/v2/cookie-consent', payload)
      .then(() => {})
      .catch(() => {});
  }, []);

  const updateCustomerId = useCallback(async (cookieConsentId) => {
    await api
      .put(`/v2/cookie-consent/${cookieConsentId}/update-customer-id`, null)
      .then(() => {})
      .catch(() => {});
  }, []);

  return {
    storeCookieConsent,
    updateCustomerId,
  };
};

export default useCookieConsent;
