/* eslint-disable react/no-danger */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Text } from '@printi/printi-components';
import { customerServiceInfoLoadRequest } from '@store/customerServiceInfo/actions';

import * as S from './Footer.styles';
import IconPayments from './icons/IconPayments';

const BottomFooter = () => {
  const cloudFront = process.env.CLOUDFRONT;
  const dispatch = useDispatch();
  const { customerServiceInfo } = useSelector((state) => state.customerServiceInfo);

  useEffect(() => {
    if (!customerServiceInfo) {
      dispatch(customerServiceInfoLoadRequest());
    }
  }, [customerServiceInfo, dispatch]);

  return (
    <S.BottomFooter>
      <Row>
        <Col xs={12} md={5} lg={3}>
          <Text size="xs" margin="0 0 .5rem">
            Site Seguro
          </Text>
          <S.StampList>
            <S.LinkStamp
              href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.printi.com.br%2F"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Google transparency report"
            >
              <picture>
                <source srcSet={`${cloudFront}/next/google-safe-browsing.png?webp=1`} type="image/webp" />
                <source srcSet={`${cloudFront}/next/google-safe-browsing.png`} type="image/jpeg" />
                <img src={`${cloudFront}/next/google-safe-browsing.png`} alt="" width="98" height="30" />
              </picture>
            </S.LinkStamp>
            <S.LinkStamp
              kind="text"
              href="https://www.reclameaqui.com.br/empresa/printi"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Reclame Aqui"
            >
              <picture>
                <source srcSet={`${cloudFront}/next/badge-reclameaqui-1000.png?webp=1`} type="image/webp" />
                <source srcSet={`${cloudFront}/next/badge-reclameaqui-1000.png`} type="image/jpeg" />
                <img src={`${cloudFront}/next/badge-reclameaqui-1000.png`} alt="" width="83" height="45" />
              </picture>
            </S.LinkStamp>
          </S.StampList>
        </Col>
        <Col xs={12} md={7} lg={3}>
          {customerServiceInfo && <S.CustomerService dangerouslySetInnerHTML={{ __html: customerServiceInfo }} />}
        </Col>
        <Col xs={12} lg={6}>
          <Text size="xs" margin="0 0 .5rem">
            Formas de pagamento
          </Text>

          <S.PaymentList>
            <S.PaymentListItem title="Pix">
              <IconPayments name="pix" />
            </S.PaymentListItem>
            <S.PaymentListItem title="Visa">
              <IconPayments name="visa" />
            </S.PaymentListItem>
            <S.PaymentListItem title="Mastercard">
              <IconPayments name="mastercard" />
            </S.PaymentListItem>
            <S.PaymentListItem title="Elo">
              <IconPayments name="elo" />
            </S.PaymentListItem>
            <S.PaymentListItem title="Amex">
              <IconPayments name="amex" />
            </S.PaymentListItem>
            <S.PaymentListItem title="Boleto">
              <IconPayments name="boleto" />
            </S.PaymentListItem>
          </S.PaymentList>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text size="xs" align="center">
            FM Impressos Personalizados Ltda. - CNPJ 13.555.994/0001-54
          </Text>
        </Col>
      </Row>
    </S.BottomFooter>
  );
};

export default memo(BottomFooter);
