import React, { memo, useState } from 'react';

import { Row, Col, Icon, Hamburguer, Text } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import * as S from './Header.styles';
import HoverMenuButton from './HoverMenuButton';
import { ProductMenuMobile } from './ProductMenuMobile';
import { useProductSummaryMenu } from './useProductSummaryMenu';

const ProductMenu = dynamic(() => import('./ProductMenu'));
const Sidebar = dynamic(() => import('../Sidebar'));
const ModalGetInTouch = dynamic(() => import('./ModalGetInTouch'));

const BottomHeader = ({ show }) => {
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const [isModalOpenSales, setIsModalOpenSales] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { summaryCategories } = useProductSummaryMenu();

  const handleFocusSideMenu = ({ type, code }) => {
    const buttonClose = document.querySelector('#buttonCloseSideBar');
    if (type !== 'keyup') setIsSidebarOpen(!isSidebarOpen);
    if ((code === 'Enter' || code === 'Space') && buttonClose) buttonClose.focus();
  };
  const handleOpenMenuProduct = () => {
    setIsProductMenuOpen(!isProductMenuOpen);
    setTimeout(() => {
      document.querySelector('#categoriesMenu span')?.focus();
    }, 500);
  };

  return (
    <S.HeaderContainer show={show}>
      <Row>
        <Col xs={12} css={{ position: 'relative' }}>
          <S.Overlay isActive={isProductMenuOpen} onClick={handleOpenMenuProduct} />
          <S.BottomHeader>
            <S.BottomHeaderItem>
              <Hamburguer
                aria-label="Menu de Áreas do Site"
                id="hamburguerIcon"
                isOpen={isSidebarOpen}
                onClick={handleFocusSideMenu}
                onKeyUp={handleFocusSideMenu}
                css={{ marginRight: '1rem' }}
              />

              {isSidebarOpen && <Sidebar isSidebarOpen={isSidebarOpen} openSidebar={setIsSidebarOpen} />}

              <S.ProductMenuButton
                id="buttonOpenProductMenu"
                onClick={() => handleOpenMenuProduct()}
                isActive={isProductMenuOpen}
              >
                Todos os produtos
                <Icon
                  name={isProductMenuOpen ? 'ArrowIosUpwardOutline' : 'ArrowIosDownwardOutline'}
                  width="20"
                  height="20"
                />
              </S.ProductMenuButton>
            </S.BottomHeaderItem>
            <HoverMenuButton bottomLinks={summaryCategories} setIsModalOpenSales={setIsModalOpenSales} />
          </S.BottomHeader>
          <ProductMenu
            isActive={isProductMenuOpen}
            setIsProductMenuOpen={setIsProductMenuOpen}
            setIsModalOpenSales={setIsModalOpenSales}
          />
          {isModalOpenSales && (
            <ModalGetInTouch
              close={() => setIsModalOpenSales(false)}
              setIsProductMenuOpen={setIsProductMenuOpen}
              isActive={isProductMenuOpen}
            >
              <Text as="h1">
                <b>Ligue para Printi!</b>
              </Text>
              <div>
                <b>Televendas: </b>
                <Text as="a" color="primary" href="tel:(11) 4118-3820" id="myAnchor">
                  (11) 4118-3820
                </Text>
              </div>
              <div>
                <b>Email: </b>
                <Text as="a" color="primary" tabIndex="0" href="mailto:vendas@printi.com.br">
                  vendas@printi.com.br
                </Text>
              </div>
              <div>
                <b>Horário de atendimento:</b>
                <Text>Segunda a Sexta-feira das 08h00 às 19h00</Text>
              </div>
            </ModalGetInTouch>
          )}
        </Col>
      </Row>
      <ProductMenuMobile isActive={isProductMenuOpen} setIsProductMenuOpen={setIsProductMenuOpen} />
    </S.HeaderContainer>
  );
};

BottomHeader.propTypes = {
  show: PropTypes.bool,
};

BottomHeader.defaultProps = {
  show: true,
};

export default memo(BottomHeader);
