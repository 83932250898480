import React, { useEffect } from 'react';

import { Icon } from '@printi/printi-components';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { useProductMenu } from '../useProductMenu';
import * as S from './ProductMenu.styles';

export const ProductMenuMobile = ({ setIsProductMenuOpen, isActive }) => {
  const { asPath } = useRouter();

  const {
    categories,
    indexCategory,
    handleSelectCategory,
    handleFocusFirstProduct,
    handleExitMenu,
    selectedCategory,
    childSelect,
    handleSelectChild,
  } = useProductMenu({ setIsProductMenuOpen, isActive });

  useEffect(() => {
    setIsProductMenuOpen(false);
  }, [asPath, setIsProductMenuOpen]);

  return (
    <>
      <S.ProductMenuContainer isActive={isActive}>
        <S.ProductMenuHeader>
          <Icon name="CloseOutline" width="14px" onClick={handleExitMenu} />
        </S.ProductMenuHeader>
        <S.ProductMenuBody>
          {categories &&
            categories.map(({ id, title }, index) => (
              <S.ProductMenuItem
                key={id}
                open={index === indexCategory}
                onKeyUp={(e) => (e.code === 'Enter' || e.code === 'Space') && handleFocusFirstProduct(e)}
                onClick={() => handleSelectCategory(index)}
              >
                <S.ProductMenuItemContent open={index === indexCategory}>
                  {title} <Icon name={index === indexCategory ? 'ChevronUp' : 'ChevronDown'} width="24px" />
                </S.ProductMenuItemContent>
                {selectedCategory &&
                  selectedCategory.children.slice(0, 3).map(({ id: childId, title: childTitle, products }) => (
                    <S.ProductMenuSubItem open={index === indexCategory} key={childId}>
                      <S.ProductMenuSubItemContent onClick={(e) => handleSelectChild(childId, e)}>
                        {childTitle} <Icon name={childSelect === childId ? 'ChevronUp' : 'ChevronDown'} width="24px" />
                      </S.ProductMenuSubItemContent>
                      <S.ProductMenuLinkContent open={childSelect === childId}>
                        {products &&
                          products.map(({ id: productId, slug, title: productTitle, badge_color, badge }) => (
                            <NextLink href={`/${slug}`} as={`/${slug}`} passHref key={productId}>
                              <S.ProductListItemLink kind="text" size="sm">
                                <S.ProductListItemLinkContent>{productTitle}</S.ProductListItemLinkContent>
                                {badge && badge_color ? (
                                  <S.TagBadge kind="filled" color={badge_color} margin="0 0 0.5rem">
                                    {badge}
                                  </S.TagBadge>
                                ) : null}
                              </S.ProductListItemLink>
                            </NextLink>
                          ))}
                      </S.ProductMenuLinkContent>
                    </S.ProductMenuSubItem>
                  ))}
              </S.ProductMenuItem>
            ))}
        </S.ProductMenuBody>
      </S.ProductMenuContainer>
      <S.BackgroundContainer />
    </>
  );
};

ProductMenuMobile.propTypes = {
  isActive: PropTypes.bool.isRequired,
  setIsProductMenuOpen: PropTypes.func.isRequired,
};
