'use client';

import React from 'react';

import { PRODUCTS_INDEX_NAME } from '../constants';
import { searchClient } from '../searchClient';
import ProductItem from '../template/productItem';

function SeeAllBtn({ state, count }) {
  const link = `/busca?p=${state.query}`;
  return (
    <div style={{ textAlign: 'center' }}>
      <a href={link} target="_self" rel="noreferrer noopener" className="aa-SeeAllBtn">
        Encontrar mais produtos ({count})
      </a>
    </div>
  );
}

export const productsPlugin = {
  async getSources({ query }) {
    if (!query || query.length < 2) {
      return [];
    }

    const results = await searchClient.collections(PRODUCTS_INDEX_NAME).documents().search({
      q: query,
      query_by: 'name',
      filter_by: 'status:published',
      // highlight_full_fields: 'name',
      per_page: 4,
      infix: 'always',
      max_extra_prefix: 3,
      max_extra_suffix: 3,
    });

    return [
      {
        sourceId: 'productsPlugin',
        getItems() {
          return results.hits;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ state, Fragment }) {
            return (
              <Fragment>
                <div className="aa-SourceHeaderTitle">Produtos em {state.query}</div>
              </Fragment>
            );
          },
          item({ item, components }) {
            return <ProductItem hit={item} components={components} />;
          },
          footer({ state }) {
            return (
              results.found > 5 && (
                <div style={{ textAlign: 'center' }}>
                  <SeeAllBtn state={state} count={results.found} />
                </div>
              )
            );
          },
        },
      },
    ];
  },
};
