/* eslint-disable react/no-danger */
import React, { memo, useCallback, useState } from 'react';

import { CollapseItem, Text } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './CookiePolicyContent.styles';

const CookiePolicyContent = ({ sections, handleSwitch, cookieOptions }) => {
  const {
    value: { prefAdvertising, prefFunctional, prefAnalytical },
  } = cookieOptions;
  const initialOptions = {
    prefFunctional: prefFunctional.value,
    prefAdvertising: prefAdvertising.value,
    prefAnalytical: prefAnalytical.value,
  };

  const [activeSection, setActiveSection] = useState(2);
  const [switchOptions, setSwitchOptions] = useState(initialOptions);

  const toggleSwitch = useCallback(
    (prefName) => {
      if (prefName) {
        handleSwitch(prefName);

        setSwitchOptions((prevState) => ({
          ...prevState,
          [prefName]: !prevState[prefName],
        }));
      }
    },
    [handleSwitch],
  );

  const createCookieOptionsList = (value) => (
    <>
      <S.CookieWrapper>
        <S.CookieOptionHeader>
          <Text weight="bold">{value.type_necessary}</Text>
          <S.StyledSwitch
            id="preferences_necessary"
            name="preferences_necessary"
            value="preferences_necessary"
            onChange={() => toggleSwitch('')}
            checked
            disabled
          />
        </S.CookieOptionHeader>
        <p dangerouslySetInnerHTML={{ __html: value.purpose_necessary }} />
      </S.CookieWrapper>

      <S.CookieWrapper>
        <S.CookieOptionHeader>
          <Text weight="bold">{value.type_functional}</Text>
          <S.StyledSwitch
            id="preferences_functional"
            name="preferences_functional"
            value="preferences_functional"
            checked={switchOptions.prefFunctional}
            onChange={() => toggleSwitch('prefFunctional')}
          />
        </S.CookieOptionHeader>
        <p dangerouslySetInnerHTML={{ __html: value.purpose_functional }} />
      </S.CookieWrapper>

      <S.CookieWrapper>
        <S.CookieOptionHeader>
          <Text weight="bold">{value.type_advertising}</Text>
          <S.StyledSwitch
            id="preferences_advertising"
            name="preferences_advertising"
            value="preferences_advertising"
            checked={switchOptions.prefAdvertising}
            onChange={() => toggleSwitch('prefAdvertising')}
          />
        </S.CookieOptionHeader>
        <p dangerouslySetInnerHTML={{ __html: value.purpose_advertising }} />
      </S.CookieWrapper>

      <S.CookieWrapper>
        <S.CookieOptionHeader>
          <Text weight="bold">{value.type_analytical}</Text>
          <S.StyledSwitch
            id="preferences_analytical"
            name="preferences_analytical"
            value="preferences_analytical"
            checked={switchOptions.prefAnalytical}
            onChange={() => toggleSwitch('prefAnalytical')}
          />
        </S.CookieOptionHeader>

        <p dangerouslySetInnerHTML={{ __html: value.purpose_analytical }} />
      </S.CookieWrapper>
    </>
  );

  return (
    <div>
      {sections.map(({ value, name }, sectionIndex) => {
        if (!value.title || !name || !handleSwitch) return [];

        return (
          <S.StyledCollapse key={value.title}>
            <CollapseItem
              withBorder={false}
              maxHeight="text"
              onClick={() => setActiveSection(sectionIndex)}
              isOpen={activeSection === sectionIndex}
            >
              <Text weight="bold" as="h2">
                {sectionIndex + 1}. {value.title}
              </Text>
              {name === 'preference' ? (
                createCookieOptionsList(value)
              ) : (
                <p dangerouslySetInnerHTML={{ __html: value.description }} />
              )}
            </CollapseItem>
          </S.StyledCollapse>
        );
      })}
    </div>
  );
};

CookiePolicyContent.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleSwitch: PropTypes.func.isRequired,
  cookieOptions: PropTypes.shape(),
};

export default memo(CookiePolicyContent);
