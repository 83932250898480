import React, { memo } from 'react';

import PropTypes from 'prop-types';

const IconSocial = ({ name }) => {
  if (name === 'facebook') {
    return (
      <svg width="20" height="20" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 .2C4.417.2.667 3.942.667 8.55A8.35 8.35 0 007.7 16.8v-5.833H5.584V8.55H7.7V6.708c0-2.091 1.242-3.241 3.15-3.241.909 0 1.859.158 1.859.158v2.058h-1.05c-1.034 0-1.359.642-1.359 1.3V8.55h2.317l-.375 2.417H10.3V16.8a8.333 8.333 0 007.034-8.25C17.334 3.942 13.584.2 9 .2z" />
      </svg>
    );
  }

  if (name === 'twitter') {
    return (
      <svg width="20" height="20" viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.716 2.5a7.139 7.139 0 01-2.05.575 3.582 3.582 0 001.567-1.983 7.2 7.2 0 01-2.267.875C14.309 1.25 13.383.833 12.334.833a3.57 3.57 0 00-3.558 3.575c0 .284.033.559.092.817A10.162 10.162 0 011.5 1.492a3.537 3.537 0 00-.483 1.791c0 1.242.625 2.342 1.591 2.967a3.536 3.536 0 01-1.625-.417v.025A3.578 3.578 0 003.85 9.367a3.518 3.518 0 01-1.608.058 3.567 3.567 0 003.333 2.483 7.102 7.102 0 01-4.442 1.534c-.283 0-.566-.017-.85-.05A10.12 10.12 0 005.767 15C12.333 15 15.94 9.55 15.94 4.825c0-.158 0-.308-.008-.467.7-.5 1.3-1.133 1.784-1.858z" />
      </svg>
    );
  }

  if (name === 'instagram') {
    return (
      <svg width="20" height="20" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5.167h7A4.837 4.837 0 0117.334 5v7a4.833 4.833 0 01-4.834 4.833h-7A4.837 4.837 0 01.667 12V5A4.833 4.833 0 015.5.167zm-.166 1.666a3 3 0 00-3 3v7.334c0 1.658 1.341 3 3 3h7.333a3 3 0 003-3V4.833c0-1.658-1.342-3-3-3H5.334zm8.041 1.25a1.042 1.042 0 110 2.084 1.042 1.042 0 010-2.084zM9 4.333a4.167 4.167 0 110 8.334 4.167 4.167 0 010-8.334zM9 6a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" />
      </svg>
    );
  }

  if (name === 'linkedin') {
    return (
      <svg width="20" height="20" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.833 0A1.666 1.666 0 0115.5 1.667v11.666A1.666 1.666 0 0113.833 15H2.167A1.667 1.667 0 01.5 13.333V1.667A1.667 1.667 0 012.167 0h11.666zm-.416 12.917V8.5A2.717 2.717 0 0010.7 5.783c-.708 0-1.533.434-1.933 1.084v-.925H6.442v6.975h2.325V8.808a1.163 1.163 0 112.325 0v4.109h2.325zM3.733 4.633a1.4 1.4 0 001.4-1.4 1.405 1.405 0 10-1.4 1.4zm1.159 8.284V5.942H2.583v6.975h2.309z" />
      </svg>
    );
  }

  if (name === 'pinterest') {
    return (
      <svg width="20" height="20" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.534 16.45c.8.242 1.608.383 2.466.383A8.333 8.333 0 10.667 8.5c0 3.542 2.225 6.583 5.367 7.783-.075-.65-.15-1.725 0-2.466L6.992 9.7s-.242-.483-.242-1.25c0-1.15.717-2.008 1.534-2.008.716 0 1.05.525 1.05 1.2 0 .716-.475 1.741-.717 2.725-.142.816.433 1.533 1.267 1.533 1.483 0 2.633-1.583 2.633-3.817 0-2-1.433-3.366-3.492-3.366-2.35 0-3.733 1.75-3.733 3.591 0 .717.233 1.442.617 1.917.075.05.075.117.05.242l-.242.908c0 .142-.092.192-.233.092C4.417 11 3.8 9.483 3.8 8.258c0-2.633 1.867-5.025 5.467-5.025 2.867 0 5.1 2.059 5.1 4.792 0 2.867-1.775 5.167-4.317 5.167-.808 0-1.6-.434-1.883-.942l-.558 1.975c-.192.717-.717 1.675-1.075 2.25v-.025z" />
      </svg>
    );
  }

  if (name === 'medium') {
    return (
      <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1043.63 592.71">
        <path d="M588.67 296.36c0 163.67-131.78 296.35-294.33 296.35S0 460 0 296.36 131.78 0 294.34 0s294.33 132.69 294.33 296.36m322.89 0c0 154.06-65.89 279-147.17 279s-147.17-124.94-147.17-279 65.88-279 147.16-279 147.17 124.9 147.17 279m132.08 0c0 138-23.17 249.94-51.76 249.94s-51.75-111.91-51.75-249.94 23.17-249.94 51.75-249.94 51.76 111.9 51.76 249.94" />
      </svg>
    );
  }

  return null;
};

IconSocial.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(IconSocial);
