import { normalizeReshapeSources } from './normalizeReshapeSources';

export const uniqBy = (predicate) =>
  function runUniqBy(...rawSources) {
    const sources = normalizeReshapeSources(rawSources);
    const seen = new Set();

    return sources.map((source) => {
      const items = source.getItems().filter((item) => {
        const appliedItem = predicate({ source, item });
        const hasSeen = seen.has(appliedItem);

        seen.add(appliedItem);

        return !hasSeen;
      });

      return {
        ...source,
        getItems() {
          return items;
        },
      };
    });
  };
