import React, { memo } from 'react';

import { Modal, Button } from '@printi/printi-components';
import PropTypes from 'prop-types';

import { useProductMenu } from '../useProductMenu';
import * as S from './styles';

const ModalGetInTouch = ({ close, children, setIsProductMenuOpen, isActive }) => {
  const { handleBackMenu } = useProductMenu({ setIsProductMenuOpen, isActive });

  return (
    <Modal size="small" infos={{ title: '' }} showActions={false} hideCancelButton close={close} func={handleBackMenu}>
      <S.ContactContainer>
        <S.ContactContent>{children}</S.ContactContent>
      </S.ContactContainer>
      <S.Button>
        <Button
          tabIndex="0"
          onClick={() => {
            close();
            handleBackMenu();
          }}
        >
          OK
        </Button>
      </S.Button>
    </Modal>
  );
};

ModalGetInTouch.propTypes = {
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  setIsProductMenuOpen: PropTypes.func.isRequired,
};

export default memo(ModalGetInTouch);
