import { Collapse, Switch, theme } from '@printi/printi-components';
import styled, { css } from 'styled-components';

const { space, color, breakpoint, font, borders } = theme;

export const StyledCollapse = styled(Collapse)`
  margin-bottom: ${space.xs};

  h2 {
    padding-top: 0;
  }

  > div > div:first-child {
    height: ${space.xl2};
  }

  @media (max-width: ${breakpoint.sm}) {
    h2 {
      font-size: ${font.size.root};
    }
  }
`;

export const StyledSwitch = styled(Switch)`
  ${(p) =>
    p.checked &&
    css`
      span {
        background-color: ${color.primary.pure};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

export const CookieWrapper = styled.div`
  padding: ${space.md} 0;
  margin: 0 ${space.xs};

  &:not(:last-of-type) {
    border-bottom: ${borders.sizes.sm} solid ${color.grayscale.light};
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const CookieOptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
