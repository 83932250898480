import { useState, useCallback } from 'react';

import { customApi, parseSearchProducts } from '@utils';

const useSearchProducts = () => {
  const [products, setProducts] = useState(null);
  const [crossSelling, setCrossSelling] = useState(null);
  const [loading, setLoading] = useState(false);

  const searchProducts = useCallback(async (productName) => {
    setLoading(true);
    if (!productName) {
      setProducts(null);
      setCrossSelling(null);
      setLoading(false);
      return false;
    }

    await customApi.get(`/v1/products/search?q=${productName}`).then(({ data }) => {
      const { products: dataProducts, crossSelling: dataCrossSelling } = parseSearchProducts(data, 3, 6);
      setProducts(dataProducts);
      setCrossSelling(dataCrossSelling);
      setLoading(false);
    });
    return true;
  }, []);

  return {
    searchProducts,
    products,
    crossSelling,
    loading,
  };
};

export default useSearchProducts;
