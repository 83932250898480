import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { menuCategoriesLoadRequest } from '@store/menuCategories/actions';

export const useProductMenu = ({ setIsProductMenuOpen, isActive }) => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState();
  const [indexCategory, setIndexCategory] = useState();
  const { categories } = useSelector((state) => state.menuCategories);
  const [childSelect, setChildSelect] = useState(null);

  const handleSelectChild = (id, e) => {
    e.stopPropagation();
    setChildSelect((oldId) => (oldId === id ? null : id));
  };
  useEffect(() => {
    if (!categories) {
      dispatch(menuCategoriesLoadRequest());
    }
  }, [categories, dispatch]);

  const handleSelectCategory = useCallback(
    (index) => {
      setIndexCategory(index);
      setSelectedCategory(categories[index]);
    },
    [categories],
  );

  const handleFocusFirstCategory = () => {
    const firstCategory = document?.querySelector('#categoriesMenu span');
    if (firstCategory) firstCategory?.focus();
  };

  const handleFocusFirstProduct = useCallback((e) => {
    e.preventDefault();
    const firstProduct = document.querySelector('#productsMenu a');
    if (firstProduct) firstProduct.focus();
  }, []);

  const handleExitMenu = useCallback(
    (e) => {
      e?.preventDefault();
      setIsProductMenuOpen(!isActive);
      document.querySelector('#buttonOpenProductMenu').focus();
    },
    [isActive, setIsProductMenuOpen],
  );

  const handleBackMenu = () => {
    document.getElementById('categoriesMenu')?.focus();
    document.querySelector('#categoriesMenu #active')?.focus();
    document.getElementById('active-category')?.focus();
  };

  useEffect(() => {
    if (!isActive) return;

    const containerMenu = document.querySelector('#productMenuContainer');

    containerMenu.addEventListener('keyup', (e) => {
      if (e.code === 'Escape') handleExitMenu(e);
    });
  }, [isActive, handleExitMenu]);

  return {
    categories,
    indexCategory,
    handleSelectCategory,
    handleFocusFirstProduct,
    handleExitMenu,
    handleBackMenu,
    handleFocusFirstCategory,
    selectedCategory,
    childSelect,
    handleSelectChild,
  };
};
