import React from 'react';

import { SearchIcon } from '../components';
import { PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME } from '../constants';
import { searchClient } from '../searchClient';

export const querySuggestionsPlugin = {
  getSearchParams({ state }) {
    return {
      hitsPerPage: !state.query ? 0 : 10,
    };
  },
  async getSources({ query }) {
    if (!query || query.length < 1) {
      return [];
    }
    const results = await searchClient.collections(PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME).documents().search({
      q: query,
      query_by: 'query',
      highlight_full_fields: 'name',
      // include_fields: 'query',
      per_page: 10,
      infix: 'always',
      max_extra_prefix: 3,
      max_extra_suffix: 3,
    });

    return [
      {
        sourceId: 'querySuggestionsPlugin',
        getItems() {
          return results.hits;
        },
        getItemInputValue({ item }) {
          return item.document.query;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header() {
            return <></>;
          },
          item({ item }) {
            return <QuerySuggestionsItem hit={item} />;
          },
        },
      },
    ];
  },
};

function QuerySuggestionsItem({ hit }) {
  return (
    <div className="aa-ItemWrapper">
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--noBorder">
          <SearchIcon />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">{hit.document?.query}</div>
        </div>
      </div>
      <div className="aa-ItemActions">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type="button" className="aa-ItemActionButton" title="Fill query with">
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
}
