import React, { memo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@printi/printi-components';
import { basicCartLoadRequest } from '@store/basicCart/actions';
import { useRouter } from 'next/router';

import * as S from './Header.styles';

const Cart = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { count: cartNumber } = useSelector((state) => state.basicCart);
  const [cartIsEmpty, setcartIsEmpty] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const cartEmpty = cartNumber === 0;

  useEffect(() => {
    dispatch(basicCartLoadRequest());
    setcartIsEmpty(cartNumber === 0);
  }, [dispatch, cartNumber]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hanadleClickCart = useCallback(
    (e, slug) => {
      e.preventDefault();
      if (!cartIsEmpty || isMobile) {
        router.push(slug);

        return true;
      }

      return true;
    },
    [router, cartIsEmpty, isMobile],
  );

  return (
    <S.Cart
      aria-label={`Carrinho de Compras com ${cartNumber} ${cartNumber === 1 ? 'item' : 'itens'}`}
      href="/meu-carrinho"
      onClick={(e) => hanadleClickCart(e, '/meu-carrinho')}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <>
        <Icon name={cartEmpty ? 'ShoppingCartOutline' : 'ShoppingCart'} />
        {cartEmpty && isHovered && !isMobile && <S.EmptyCartMessage>Seu carrinho está vazio!</S.EmptyCartMessage>}
        {cartNumber > 0 && <S.CartNumber>{cartNumber}</S.CartNumber>}
      </>
    </S.Cart>
  );
};

export default memo(Cart);
