import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useWindowSize, customEvents, isCookieConsentAvailable, customApi } from '@utils';
import { useRouter } from 'next/router';

const FIRST_ACCESS = 'first-access';
export const EVENT_NAME = 'first-cookies-event';
const isCookieAvailable = isCookieConsentAvailable();

const pages = {
  '/': 'home_enabled',
  page: 'pages_enabled',
  '/[...slug]': 'product_enabled',
  configuracao: 'product_settings_enabled',
  '/upload/[...slug]': 'upload_page_enabled',
  '/meu-carrinho': 'cart_page_enabled',
};

export const useButtonWhatsApp = () => {
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [isShowButton, setIsShowButton] = useState(false);
  const [isCookieShow, setIsCookieShow] = useState(isCookieAvailable);
  const [isSettingPage, setIsSettingPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataWhatsAppStatus, setDataWhatsAppStatus] = useState({});
  const [isShowModalCookie, setIsShowModalCookie] = useState(false);

  const windowSize = useWindowSize();
  const time = useRef(null);

  const isDesktop = windowSize?.width && windowSize?.width > 768;

  const { asPath, pathname } = useRouter();

  useEffect(() => {
    customApi.get('/v1/all_whats_app/status').then(({ data }) => {
      setDataWhatsAppStatus(data);
      setIsLoading(false);
    });
  }, []);

  const offsetHeightBanner = useMemo(() => {
    const banner = document.getElementById('modal-cookie-consent');

    return banner?.offsetHeight;
  }, [isShowModalCookie, windowSize]);

  const showThisPage = useMemo(() => {
    if (asPath.includes('configuracao')) {
      return dataWhatsAppStatus[pages.configuracao];
    }

    const pageCurrent = pages[pathname];
    return dataWhatsAppStatus[pageCurrent];
  }, [asPath, pathname, dataWhatsAppStatus]);

  useEffect(() => {
    setIsCookieShow(isCookieConsentAvailable());
  }, [isCookieShow]);

  useEffect(() => {
    customEvents.subscribe(EVENT_NAME, () => {
      setIsCookieShow(false);
    });
    customEvents.subscribe('show-modal-cookie', (e) => {
      setIsShowModalCookie(e.detail.show);
    });
    return () => {
      customEvents.unsubscribe(EVENT_NAME);
      customEvents.unsubscribe('show-modal-cookie');
    };
  }, []);

  useEffect(() => {
    setIsSettingPage(asPath.includes('configuracao'));
  }, [asPath]);

  const handleHiddenMessage = useCallback(() => {
    time.current = setTimeout(() => {
      setIsShowMessage(false);
    }, 5000);
  }, []);

  const handleShowMessage = useCallback(() => {
    clearTimeout(time.current);
    setIsShowMessage(true);
  }, []);

  const hoverShowMessage = useCallback(() => {
    clearTimeout(time.current);
    if (isDesktop) setIsShowMessage(true);
  }, [isDesktop]);

  useEffect(() => {
    const verifyFirstAccess = () => {
      const isFirstAccess = localStorage.getItem(FIRST_ACCESS);
      if (!isFirstAccess && isShowButton) {
        localStorage.setItem(FIRST_ACCESS, 'on');
        setTimeout(() => {
          handleShowMessage();
          handleHiddenMessage();
        }, 6000);
      }
    };

    verifyFirstAccess();
  }, [handleHiddenMessage, handleShowMessage, isShowButton]);

  const handleShowButton = (showDesktop, showMobile) => {
    if (showDesktop || showMobile) {
      setTimeout(() => {
        setIsShowButton(true);
      }, 3000);
    }
  };

  useEffect(() => {
    const showDesktop = isDesktop && !isLoading && showThisPage;
    const showMobile = !isDesktop && !isCookieShow && !isLoading && showThisPage && asPath !== '/meu-carrinho';
    handleShowButton(showDesktop, showMobile);
  }, [asPath, isCookieShow, isDesktop, isLoading, showThisPage]);

  return {
    isSettingPage,
    isShowButton,
    isShowMessage,
    handleShowMessage,
    handleHiddenMessage,
    dataWhatsAppStatus,
    offsetHeightBanner,
    hoverShowMessage,
  };
};
