import React from 'react';

function IconWhatsApp({ size, margin }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 30}
      height={size || 30}
      fill="none"
      viewBox="0 0 35 35"
      style={{ marginBottom: margin }}
    >
      <path
        fill="#111B21"
        d="M17.352 0C7.874 0 .157 7.78.139 17.333c0 3.056.784 6.041 2.3 8.676L0 35l9.129-2.406a17.01 17.01 0 008.223 2.108c9.477 0 17.195-7.78 17.212-17.351a17.37 17.37 0 00-5.035-12.276C26.271 1.81 21.951 0 17.352 0zm0 31.769c-2.579 0-5.087-.703-7.282-2.002l-.523-.316-5.418 1.422 1.446-5.321-.349-.544a14.367 14.367 0 01-2.177-7.675c0-7.955 6.41-14.418 14.303-14.418 3.815 0 7.404 1.51 10.104 4.233 2.7 2.722 4.181 6.34 4.181 10.203 0 7.955-6.41 14.418-14.285 14.418zm7.84-10.8c-.436-.211-2.544-1.265-2.945-1.405-.4-.141-.68-.211-.976.21-.278.44-1.114 1.405-1.358 1.704-.244.28-.506.333-.924.105-.435-.21-1.812-.667-3.45-2.142-1.27-1.142-2.142-2.564-2.386-3.003-.244-.44-.035-.668.192-.878.191-.194.435-.51.644-.756.21-.245.28-.439.436-.72.14-.28.07-.544-.035-.755-.104-.21-.976-2.353-1.324-3.214-.348-.843-.697-.737-.976-.737-.243-.018-.54-.018-.818-.018-.28 0-.75.106-1.15.545-.4.439-1.498 1.475-1.498 3.617 0 2.125 1.533 4.197 1.76 4.478.208.282 3.03 4.672 7.351 6.55 1.028.44 1.83.721 2.456.914 1.028.334 1.969.281 2.7.176.82-.123 2.544-1.054 2.91-2.055.366-1.018.366-1.879.244-2.055-.14-.228-.418-.333-.854-.562z"
      ></path>
    </svg>
  );
}

export default IconWhatsApp;
