import { theme, Link, Tag } from '@printi/printi-components';
import styled, { keyframes } from 'styled-components';

const { breakpoint, borders, color, space, font } = theme;

const slideIn = keyframes`
  from {
    transform: translateX(-${space.xl2});
  }

  to {
    transform: translateX(0);
  }
`;

export const BackgroundContainer = styled.div`
  z-index: 9999;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  width: 100%;
  height: 100vh;
  display: none;
  @media (max-width: ${breakpoint.md}) {
    display: ${(p) => (p.isActive ? 'block' : 'none')};
  }
`;

export const ProductMenuContainer = styled.nav`
  z-index: 9999;
  position: fixed;
  background: ${color.grayscale.white};
  top: 0;
  width: 314px;
  height: 100vh;
  padding: ${font.size.xsmall} ${font.size.small};
  box-shadow: 0px 0px ${font.size.small};
  display: none;
  overflow-y: scroll;

  @media (max-width: ${breakpoint.md}) {
    display: ${(p) => (p.isActive ? 'block' : 'none')};
    animation: ${slideIn} 0.2s linear;
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none ${color.grayscale.white};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none ${color.grayscale.white};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const ProductMenuHeader = styled.div`
  text-align: right;
`;

export const ProductMenuBody = styled.div``;

export const ProductMenuItem = styled.div`
  border-bottom: solid ${borders.sizes.sm} ${color.grayscale.lighter};
  padding: 0 0 ${font.size.small} 0;
  cursor: pointer;
`;

export const ProductMenuItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${font.size.sm};
  font-weight: ${font.weight.bold};
  line-height: 22px;
  color: ${(p) => (p.open ? color.primary.pure : color.grayscale.darker)};
  padding: ${font.size.small} 0 0px 0;
`;

export const ProductMenuSubItem = styled.div`
  padding-left: ${font.size.h5};
  display: ${(p) => (p.open ? 'block' : 'none')};
`;

export const ProductMenuSubItemContent = styled(ProductMenuItemContent)`
  justify-content: flex-start;
  padding: 0;
  font-size: ${font.size.small};
  margin: ${font.size.small} 0;
`;

export const ProductMenuLinkContent = styled.div`
  display: ${(p) => (p.open ? 'flex' : 'none')};
  flex-direction: column;
  font-size: ${font.size.small};
  gap: ${font.size.small};
`;

export const ProductListItemLinkContent = styled.div`
  overflow: hidden;
  span {
    text-shadow: none;
  }
`;

export const ProductListItemLink = styled(Link)`
  color: ${color.grayscale.darker};

  display: flex;
  gap: 8px;

  &:hover {
    color: ${color.primary.pure};
    text-shadow: 0 0 0.65px ${color.primary.pure}, 0 0 0.65px ${color.primary.pure};
  }

  &::before {
    height: 0rem;
  }
`;

export const TagBadge = styled(Tag)`
  margin: auto 0.2rem;
  font-size: 8xp;
`;
