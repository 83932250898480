import { theme, Link } from '@printi/printi-components';
import styled from 'styled-components';
const { space, color, font, borders } = theme;

export const Background = styled.div`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
`;

export const Sidebar = styled.menu`
  display: block;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16rem;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  padding: ${space.xl};
  background-color: ${color.grayscale.black};
  transform: ${(p) => (p.isOpen ? 'translateX(0)' : 'translateX(-16rem)')};
  transition: all 0.27s ease;
`;

export const Close = styled.button`
  position: absolute;
  top: ${space.sm};
  right: ${space.sm};
  border-radius: 50%;
  background-color: transparent;
  border: 0;

  &:focus {
    border: ${borders.sizes.md} ${color.grayscale.white} solid;
  }
`;

export const NavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${space.xl};

  a:focus {
    outline: 0.125rem solid ${color.primary.pure};
  }
`;

export const MenuLink = styled(Link)`
  clear: both;
  margin-bottom: ${space.xs};
  font-size: ${font.size.small};
`;
