import React, { useEffect, useState } from 'react';

import { Text } from '@printi/printi-components';

const Newsletter = () => {
  const [loading, setLoading] = useState(true);

  const HandleLoadHubspot = () => {
    const hubspotForm = document.getElementById('hubspot-form');

    if (!hubspotForm) {
      const script = document.createElement('script');
      script.id = 'hubspot-form';
      script.setAttribute('type', 'text/javascript');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      document.head.append(script);

      script.addEventListener('load', () => {
        const hubscript = document.createElement('script');
        hubscript.id = 'hubspot-script';
        // eslint-disable-next-line no-undef
        hbspt.forms.create({
          region: 'na1',
          portalId: '44103013',
          formId: '4b33f724-6183-464a-93fb-1d73a0521730',
          target: '.hubspot-newsletter-warpper',
        });
        setLoading(false);
      });
    } else {
      hubspotForm.remove();
      HandleLoadHubspot();
    }
  };

  useEffect(() => {
    HandleLoadHubspot();
  }, []);

  return loading ? (
    <Text>Carregando...</Text>
  ) : (
    <div id="hubspot-newsletter-warpper" className="hubspot-newsletter-warpper"></div>
  );
};

export default Newsletter;
