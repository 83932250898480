import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@printi/printi-components';
import { localeLoadRequest } from '@store/locale/actions';
import PropTypes from 'prop-types';

const Locale = ({ children }) => {
  const dispatch = useDispatch();
  const { locale, localeLoading } = useSelector((state) => state.locale);

  useEffect(() => {
    if (!locale && !localeLoading) {
      dispatch(localeLoadRequest());
    }
  }, [dispatch, locale, localeLoading]);

  return localeLoading || !locale ? <Loader size="medium" wrapper="100vh" /> : children;
};

Locale.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Locale);
