import React, { memo, useEffect } from 'react';

import { analytics, isAdminUser } from '@utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { ButtonWhatsApp } from '../ButtonWhatsApp';
import * as S from './Page.styles';
import PreviewMode from './PreviewMode';

const Header = dynamic(() => import('../Header'));
const Head = dynamic(() => import('./Head'));
const Footer = dynamic(() => import('../Footer'));
const ModalCookieConsent = dynamic(() => import('../ModalCookieConsent'));

const Page = ({ seo, children, bottomPadding }) => {
  const router = useRouter();
  const isAdmin = isAdminUser();

  useEffect(() => {
    analytics(router.asPath);
  }, [router.asPath]);

  return (
    <S.Page bottomPadding={bottomPadding}>
      <ModalCookieConsent />
      {seo && <Head seo={seo} />}
      <Header />
      <main id="main">{children}</main>
      <ButtonWhatsApp />
      <Footer />
      <PreviewMode isAdmin={isAdmin} router={router} />
    </S.Page>
  );
};

Page.propTypes = {
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    copyright: PropTypes.string,
    abstract: PropTypes.string,
    robots: PropTypes.string,
    type: PropTypes.string,
  }),
  children: PropTypes.node,
  bottomPadding: PropTypes.bool,
};

Page.defaultProps = {
  bottomPadding: false,
};

export default memo(Page);
