import React from 'react';

import { Icon } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './Page.styles';

const PreviewMode = ({ isAdmin, router }) => (
  <>
    {isAdmin &&
      (localStorage.getItem('previewMode') === 'true' ? (
        <S.ButtonPreviewMode
          onClick={() => {
            localStorage.setItem('previewMode', 'false');
            window.location.href = `/api/disable-preview?slug=${router.asPath}`;
          }}
        >
          <Icon name="EyeOffOutline" size="sm" title="Olho fechado" /> Preview
        </S.ButtonPreviewMode>
      ) : (
        <S.ButtonPreviewMode
          onClick={() => {
            localStorage.setItem('previewMode', 'true');
            window.location.href = `/api/preview?slug=${router.asPath}`;
          }}
        >
          <Icon name="EyeOutline" size="sm" title="Olho aberto" />
          Preview
        </S.ButtonPreviewMode>
      ))}
  </>
);

PreviewMode.propTypes = {
  isAdmin: PropTypes.bool,
  router: PropTypes.object,
};

export default PreviewMode;
