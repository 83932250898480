import React from 'react';

import { SearchIcon } from '../components';
import { PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME } from '../constants';
import { searchClient } from '../searchClient';
export const popularPlugin = {
  async getSources({ query }) {
    const results = await searchClient.collections(PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME).documents().search({
      q: query,
      query_by: 'query',
      per_page: 10,
    });

    return [
      {
        sourceId: 'popularPlugin',
        getItems() {
          return results.hits;
        },
        getItemInputValue({ item }) {
          return item.document?.query;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ Fragment }) {
            return (
              <>
                <h4 className="aa-SourceHeaderTitle">Buscas populares</h4>
              </>
            );
          },
          item({ item }) {
            return <PopularItem hit={item} />;
          },
        },
      },
    ];
  },
};

function PopularItem({ hit }) {
  return (
    <div className="aa-ItemWrapper">
      <div className="aa-ItemIcon aa-ItemIcon--noBorder">
        <SearchIcon />
      </div>
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">{hit.document?.query}</div>
        </div>
      </div>
    </div>
  );
}
