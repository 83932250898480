import { theme } from '@printi/printi-components';
import styled from 'styled-components';

const { font } = theme;

export const CookiePolicyContent = styled.div`
  th {
    font-weight: ${font.weight.bold};
  }

  label {
    display: inline-block;
  }

  td {
    p {
      margin-bottom: 0;
    }

    &:first-child {
      width: 60%;
    }

    &:last-child {
      text-align: end;
      width: 4%;
    }
  }

  td:nth-child(2),
  th:nth-child(2) {
    text-align: center;
  }
`;
