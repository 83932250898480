import { theme, Link, Text, Button, Col, Tag } from '@printi/printi-components';
import styled, { keyframes } from 'styled-components';
const { breakpoint, borders, color, space, font } = theme;

const slideIn = keyframes`
  from {
    transform: translateY(-${space.xl2});
  }

  to {
    transform: translateY(0);
  }
`;

export const Header = styled.header`
  z-index: 10;
  margin-bottom: ${space.xl};
  position: sticky;
  top: -${(props) => props?.top}px;
`;

export const H1TitleLogo = styled.h1`
  margin-bottom: 0;
  font-size: 0;
`;

export const BannerHeader = styled.header`
  figure {
    margin-bottom: 0;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const SkipLink = styled.a`
  position: absolute;
  top: -10rem;
  left: 0;
  transition: top 0.3s ease;
  padding: ${space.xs} ${space.md};
  background-color: ${color.primary.pure};
  color: ${color.grayscale.white};
  font-size: ${font.size.root};
  font-weight: ${font.weight.bold};

  &:focus {
    top: 0;
  }
`;

export const TopHeader = styled.div`
  padding: ${space.xs2} 0;
  border-bottom: ${borders.sizes.sm} solid #ebedf0;
  background-color: ${color.grayscale.white};
`;

export const LinkListRow = styled('div')`
  @media (max-width: ${breakpoint.lg}) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const LinkList = styled('nav')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${space.md};
  @media (max-width: ${breakpoint.lg}) {
    flex-direction: column-reverse;
    gap: 0;
    align-items: flex-end;
  }
  @media (min-width: ${breakpoint.lg}) {
    padding: 0px ${space.lg};
  }
`;

export const TopHeaderLinks = styled(Link)`
  font-size: ${font.size.xsmall};
  font-family: Circular;
  display: flex;
  gap: 8px;
  &:not(:first-of-type) {
    margin-left: ${space.md};
  }

  svg {
    display: ${(props) => (props.showIcon ? 'inline-block' : 'none')};

    @media (min-width: ${breakpoint.lg}) {
      display: inline-block;
      margin-left: ${space.xs2};
    }
  }
`;

export const MainHeader = styled.div`
  padding: ${space.sm} 0;
  border-bottom: ${borders.sizes.sm} solid #ebedf0;
  background-color: ${color.grayscale.white};
`;

export const LogoLink = styled.a`
  svg {
    max-width: 100%;
  }
`;

export const MainHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Overlay = styled.div`
  display: ${(p) => (p.isActive ? 'block' : 'none')};
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
`;

export const SearchProduct = styled.div`
  position: relative;
  width: 100%;
  z-index: ${(p) => (p.isActive ? 99 : 0)};
  margin-top: ${space.sm};

  @media (min-width: ${breakpoint.xl}) {
    margin-top: 0;
  }
`;

export const SearchResult = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: 1rem ${space.xs};
  padding: ${space.md} 0;
  background-color: ${color.grayscale.white};
  box-shadow: 0rem 0.5rem 0.5rem 0 rgba(83, 117, 153, 0.5);
`;
export const SearchProductBox = styled.div`
  position: relative;
  z-index: 9999;
`;
export const ListView = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;
export const SideSearch = styled.div`
  display: none;
  justify-content: flex-start;
  min-width: 30%;
  height: 100%;
  padding: 0 1rem;

  @media (min-width: ${breakpoint.md}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MainResult = styled.div`
  min-width: 70%;
  width: 100%;
  padding-left: 1rem;
  border-right: ${borders.sizes.sm} solid ${color.grayscale.lighter};
`;

export const ProductList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Product = styled.a`
  position: relative;
  flex: 0 1 auto;
  width: 33.3%;
  padding: 1rem ${space.xs};
  text-align: center;
  cursor: pointer;
  transition: opacity 0.4s ease;

  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
`;

export const ProductImage = styled.div`
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0 ${space.sm};
  height: ${space.xl2};
`;

export const LinkHeader = styled.a`
  position: relative;
  margin-right: ${space.md};
  color: ${color.grayscale.black};
  font-size: ${font.size.small};
  font-weight: ${font.weight.bold};
  transition: color 0.4s ease;

  &:hover {
    color: ${color.primary.pure};
    text-decoration: none;
  }
`;

export const Cart = styled.a`
  display: block;
  position: relative;
  height: ${space.xl};
  padding: 0;
  border: 0;
  color: ${color.grayscale.black};
`;

export const CartNumber = styled.span`
  display: block;
  position: absolute;
  top: -0.3125rem;
  right: -0.375rem;
  width: 1.125rem;
  height: 1.125rem;
  background-color: ${color.danger.pure};
  color: ${color.grayscale.white};
  font-size: ${font.size.xxsmall};
  border-radius: 50%;
  text-align: center;
  line-height: 1.125rem;
  font-weight: ${font.weight.bold};
`;

export const HeaderContainer = styled.div`
  background-color: ${color.grayscale.white};
  z-index: -1;
  @media (min-width: ${breakpoint.md}) {
    display: ${(props) => (!props.show ? 'none' : 'block')};
    animation: ${slideIn} 0.2s linear;
  }
`;

export const BottomHeader = styled.ul`
  display: flex;
  align-items: center;
  gap: ${space.xl};
  padding: 0;
  margin: 0;
  height: ${space.xl2};
  word-break: keep-all;
`;

export const BottomHeaderItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  height: 2.5rem;
  font-size: ${font.size.small};

  #buttonOpenProductMenu {
    outline-offset: 0px;
  }
  button {
    color: #000;
    border: none;
    border-radius: 0px;
    outline-offset: 2px;
    padding-inline: 2px;
    :hover {
      color: #009bff;
    }
  }

  &:nth-of-type(2),
  &:nth-of-type(3),
  &:nth-of-type(4),
  &:nth-of-type(5),
  &:nth-of-type(6),
  &:nth-of-type(7),
  &:nth-of-type(8),
  &:nth-of-type(9),
  &:nth-of-type(10),
  &:nth-of-type(11) {
    display: none;
  }

  @media (min-width: ${breakpoint.md}) {
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      display: flex;
    }
  }

  @media (min-width: ${breakpoint.lg}) {
    &:nth-of-type(5),
    &:nth-of-type(6) {
      display: flex;
    }
  }

  @media (min-width: ${breakpoint.xl}) {
    &:nth-of-type(7),
    &:nth-of-type(8),
    &:nth-of-type(9),
    &:nth-of-type(10) {
      display: flex;
    }
  }

  @media (min-width: ${breakpoint.hd}) {
    &:nth-of-type(10),
    &:nth-of-type(11) {
      display: flex;
    }
  }

  &:hover {
    color: ${color.primary.pure};
    font-weight: bold;
  }
`;

export const ProductMenuButton = styled.button`
  position: relative;
  z-index: ${(p) => (p.isActive ? 99 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: ${space.xl2};
  padding: 0;
  border: 0;
  border-radius: 0;
  text-align: center;
  color: ${color.grayscale.black};
  font-size: ${font.size.small};
  font-weight: ${font.weight.bold};

  &:hover {
    color: ${color.primary.pure};
  }
`;

export const ProductMenu = styled.div`
  position: absolute;
  top: 100%;
  left: -${space.sm};
  right: 0;
  margin: 0 ${space.sm};
  z-index: 99;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  border-radius: 0 0 ${borders.radius.md} ${borders.radius.md};
  background-color: ${color.grayscale.white};
  box-shadow: 0rem 0.2rem 0.5rem 0 rgba(83, 117, 153, 0.5);
  transform: ${(p) => (p.isActive ? 'translate(0, 0)' : 'translate(0, -100%)')};
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  visibility: ${(p) => (p.isActive ? 'visible' : 'hidden')};
  transition: all 0.4s ease;
  @media (max-width: ${breakpoint.md}) {
    display: none;
  }
`;
export const ProductMenuSummary = styled.div`
  position: absolute;
  top: 2rem;
  left: ${(props) => (props.outViewPort ? props.left - 60 : -35)}px;
  margin: 0 ${space.sm};
  z-index: 99;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: max-content;
  border-radius: 0 0 ${borders.radius.md} ${borders.radius.md};
  background-color: ${color.grayscale.white};
  box-shadow: 0rem 0.2rem 0.5rem 0 rgba(83, 117, 153, 0.5);
  transform: ${(p) => (p.isActive ? 'translate(0, 2%)' : 'translate(0, 0)')};
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  visibility: ${(p) => (p.isActive ? 'visible' : 'hidden')};
  transition: all 0.2s ease;
  overflow-x: auto;
`;

export const Categories = styled.nav`
  display: block;
  width: 11rem;
  min-width: 11rem;
  padding: ${space.sm} ${space.xs2} ${space.sm} ${space.sm};
  border-right: ${borders.sizes.sm} solid ${color.grayscale.lighter};
  height: 600px;

  @media (min-width: ${breakpoint.md}) {
    width: 12rem;
    min-width: 12rem;
    padding: ${space.md};
  }
`;

export const CategoryItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${space.xs};
  color: ${(p) => (p.isActive ? p.theme.color.primary.pure : p.theme.color.grayscale.black)};
  font-size: ${font.size.small};
  font-weight: ${font.weight.bold};
  cursor: pointer;

  &:hover {
    color: ${color.primary.pure};
  }
`;

export const Products = styled.div`
  width: calc(100% - 12rem);
  height: 32rem;
  margin: 0;
  padding: ${space.md};
  overflow-x: auto;
  overflow-y: hidden;

  p {
    white-space: nowrap;
  }
`;

export const ProductsSummary = styled.div`
  width: 100%;
  height: auto;
  max-height: 25rem;
  margin: 0;
  padding: ${space.md};
  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};
  p {
    white-space: nowrap;
  }
`;

export const ProductsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: calc(100% - ${space.xl});
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ProductsListItem = styled.li`
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  margin-bottom: ${space.xs};
  @media (min-width: ${breakpoint.xl}) {
    width: 33.3%;
  }
  a {
    white-space: nowrap;
  }
`;
export const ProductsListItemLevel2 = styled.li`
  display: inline-flex;
  justify-content: flex-start;
  margin-bottom: ${space.xs};

  a {
    white-space: nowrap;
  }
`;
export const StyledText = styled(Text)`
  width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;
export const StyledLink = styled(Link)`
  color: ${color.grayscale.darker};
  font-size: ${font.size.sm};
  display: flex;
  gap: 8px;

  &:hover {
    color: ${color.primary.pure};
  }

  &::before {
    height: 0rem;
  }
`;
export const ProductsListSummary = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100% - ${space.xl});
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: ${font.size.small};
  font-weight: normal;
`;

export const ProductsListItemSummary = styled.li`
  display: inline-flex;
  justify-content: flex-start;
  margin-bottom: ${space.xs};

  a {
    white-space: nowrap;
  }
  &:hover {
    text-decoration: none;
  }
`;

export const ProductListItemLink = styled(Link)`
  color: ${color.grayscale.darker};

  &:hover {
    color: ${color.primary.dark};
  }

  &::before {
    height: 0rem;
  }

  span {
    text-shadow: none;
  }
`;

export const JumpFocus = styled(Text)`
  font-size: 0.875rem;
  cursor: none;
  opacity: 0;

  &:focus {
    opacity: 1;
  }
`;

export const ContentCol = styled(Col)`
  display: flex;
`;
export const SubCategoryRow = styled.div`
  flex-wrap: nowrap;
`;
export const SubCategory = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: ${space.md};
`;
export const TagBadge = styled(Tag)`
  margin: auto 0.2rem;
  font-size: ${font.size.xsmall};
`;

export const ProductsCategorySummary = styled.div`
  width: 100%;
  height: auto;
  max-height: 25rem;
  margin: 0;
  flex-basis: 33%;
  p {
    white-space: nowrap;
  }
`;

export const EmptyCartMessage = styled.div`
  background-color: #d4dade;
  color: var(--azul-5, #005d99);
  text-align: center;
  font-family: 'Circular';
  font-size: 16px;
  font-style: italic;
  font-weight: 450;
  line-height: 140%;
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-100%);
  z-index: 1;
  white-space: nowrap;
  max-width: 200px;
  padding: 8px 12px;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 100%;
    margin-left: -15px;
    border-width: 8px;
    border-style: solid;
    border-color: #d4dade transparent transparent transparent;
    transform: rotate(185deg);
    width: 15px;
    height: 15px;
  }
`;

export const AccessibilityBtn = styled.div`
  button {
    display: block;
    color: #007ccc;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0.5rem;
    padding: 0;
    min-width: auto;
    height: auto;
    border: none;

    &:focus {
      opacity: 1;
    }
  }
`;

export const TopLink = styled('a')`
  :hover {
    text-decoration: none !important;
  }
`;

export const LinksWrapper = styled('div')`
  display: flex;
  gap: ${space.md};
  @media (max-width: ${breakpoint.lg}) {
    width: 100%;
    border-top: ${(props) => (props.last ? '0' : borders.sizes.sm)} solid ${color.grayscale.lighter};
    justify-content: flex-end;
    padding: 3px ${space.lg} 0px;
  }

  @media (max-width: ${breakpoint.sm}) {
    padding: 3px ${space.md} 0px;
  }
`;

export const Divider = styled('div')`
  height: 10px;
  width: 1px;
  background: ${color.grayscale.lighter};
  display: none;
  @media (min-width: ${breakpoint.lg}) {
    display: block;
  }
`;
