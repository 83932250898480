/* eslint-disable react/no-danger */
import React from 'react';

import * as S from './CookiePolicy.styles';
import CookiePolicyContent from './CookiePolicyContent';

const prepareCookiePolicyDate = (policyDate) => {
  const pDate = new Date(policyDate);

  return `${pDate.getFullYear()}-${pDate.getMonth() + 1}-${pDate.getDate()}`;
};

const prepareCookiePolicyText = (data, handleSwitch, cookieOptions) => (
  <>
    <h1>{data.policy_heading}</h1>
    <S.CookiePolicyContent>
      {Array.isArray(data.policy_sections) && (
        <CookiePolicyContent
          handleSwitch={handleSwitch}
          sections={data.policy_sections}
          cookieOptions={cookieOptions}
        />
      )}
    </S.CookiePolicyContent>
  </>
);

const CookiePolicy = (data, handleSwitch, cookieOptions) => {
  const cookieConsentText = data.consent_text;
  const cookiePolicyVersion = data.policy_version;
  const cookiePolicyDate = prepareCookiePolicyDate(data.policy_date);
  const cookiePolicyText = prepareCookiePolicyText(data, handleSwitch, cookieOptions);
  const isCookieContentValid = !!(cookieConsentText && cookiePolicyVersion && cookiePolicyDate && cookiePolicyText);

  localStorage.setItem('cookiePolicyVersion', cookiePolicyVersion);

  return {
    cookieConsentText,
    cookiePolicyVersion,
    cookiePolicyDate,
    cookiePolicyText,
    isCookieContentValid,
  };
};

export default CookiePolicy;
