import { CookieConsent, theme } from '@printi/printi-components';
import styled from 'styled-components';

const { space, breakpoint } = theme;

export const StyledCookieConsent = styled(CookieConsent)`
  @media (min-width: ${breakpoint.md}) {
    button:first-child {
      margin-left: ${space.xl2};
    }

    div > p {
      margin-bottom: 0;
    }
  }
`;
