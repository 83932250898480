import React, { memo, useCallback, useEffect, useState } from 'react';
import { InstantSearch, Configure, useRefinementList } from 'react-instantsearch';

import { Col } from '@printi/printi-components';
import { customEvents } from '@utils';
import algoliasearch from 'algoliasearch/lite';

import * as S from '../Header.styles';
import { CustomHits } from './CustomHits';
import { CustomSearchBox } from './CustomSearchBox';

import 'instantsearch.css/themes/algolia.css';
import 'instantsearch.css/themes/reset.css';
import 'instantsearch.css/themes/satellite.css';
import { EVENT_OPEN_SEARCH } from './event-type';

const appId = process.env.ALGOLIA_APP_ID;
const apiKey = process.env.ALGOLIA_API_KEY;
const algoliaIndexKey = process.env.ALGOLIA_INDEX_KEY;

const searchClient = algoliasearch(appId, apiKey);

function CustomLayout(props) {
  const { items } = useRefinementList(props);
  return (
    <div className="aa-Panel-Custom">
      <S.ContentCol noGutter>
        <Col data-insights-index={algoliaIndexKey} xs={items.length > 0 ? 9 : 12} id="content-search">
          <CustomHits {...props} />
        </Col>
      </S.ContentCol>
    </div>
  );
}

const AlgoliaSearch = () => {
  const [autocompleteState, setAutocompleteState] = useState({});
  const [searchValue, setSearchValue] = useState();

  const handleChange = useCallback((value) => {
    setAutocompleteState((oldState) => ({ ...oldState, isOpen: value }));
  }, []);

  const handleQueryValue = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const handleOpenSearch = () => {
    setAutocompleteState((oldState) => ({ ...oldState, isOpen: false }));
  };

  useEffect(() => {
    customEvents.subscribe(EVENT_OPEN_SEARCH, () => {
      handleOpenSearch();
    });
    return () => {
      customEvents.unsubscribe(EVENT_OPEN_SEARCH);
    };
  }, []);

  return (
    <InstantSearch searchClient={searchClient} indexName={algoliaIndexKey}>
      <Configure ruleContexts={[]} facetFilters={['visible:true']} clickAnalytics userToken="user-1" hitsPerPage={3} />
      <S.Overlay isActive={autocompleteState.isOpen} onClick={handleOpenSearch} />
      <S.SearchProduct isActive={autocompleteState.isOpen}>
        <div className="aa-InputWrapper">
          <CustomSearchBox setAutocompleteState={handleChange} setQueryValue={handleQueryValue} />
        </div>
        <CustomLayout
          attribute="category"
          isActive={autocompleteState.isOpen}
          searchValue={searchValue}
          handleOpenSearch={handleOpenSearch}
        />
      </S.SearchProduct>
    </InstantSearch>
  );
};

export default memo(AlgoliaSearch);
