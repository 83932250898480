/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { memo, useCallback, useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import * as S from './Header.styles';
import { ProductMenuSummaryComponent } from './ProductMenuSummaryComponent';

const CategoryDropdown = ({ bottomLinks, setIsModalOpenSales }) => {
  const [isActive, setIsActive] = useState(false);
  const [indexCategory, setIndexCategory] = useState();
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);

  useEffect(() => {
    const handleMouseMove = () => {
      setIsKeyboardNavigation(false);
    };

    const handleMouseStopMoving = () => {
      setIsKeyboardNavigation(true);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseenter', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseStopMoving);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseenter', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseStopMoving);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'NumpadEnter') {
        setIsKeyboardNavigation(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleModalIsOpen = () => {
    setIsModalOpenSales(true);

    setTimeout(() => {
      document.getElementById('myAnchor')?.focus();
    }, 100);
  };

  const handleSelectCategory = useCallback((index) => {
    setIsActive(true);
    setIndexCategory(() => index);
  }, []);

  const handleHoverOff = useCallback(() => {
    setIsActive(false);
    setIndexCategory(null);
  }, []);

  return (
    <>
      {bottomLinks &&
        bottomLinks.map(({ id, title, children }, index) => (
          <S.BottomHeaderItem
            onMouseOver={() => handleSelectCategory(index)}
            onMouseLeave={handleHoverOff}
            key={id}
            id={`category-${index}`}
          >
            <button
              id={index === indexCategory ? 'active-category' : index}
              type="button"
              tabIndex="0"
              onKeyUp={({ code }) => {
                if (code === 'Enter' || code === 'Space') {
                  handleSelectCategory(index);
                }
                if (code === 'Escape') {
                  handleHoverOff();
                }
              }}
            >
              {title}
            </button>
            <ProductMenuSummaryComponent
              indexCategory={indexCategory}
              indexSelf={index}
              setIsActive={setIsActive}
              setIndexCategory={setIndexCategory}
              isActive={isActive}
              selectedCategory={children}
              openModal={handleModalIsOpen}
              isKeyboardNavigation={isKeyboardNavigation}
              setIsKeyboardNavigation={setIsKeyboardNavigation}
            />
          </S.BottomHeaderItem>
        ))}
    </>
  );
};

CategoryDropdown.propTypes = {
  bottomLinks: PropTypes.object.isRequired,
  setIsModalOpenSales: PropTypes.func.isRequired,
};

export default memo(CategoryDropdown);
