import React, { memo, useState, useEffect, useCallback } from 'react';

import { api } from '@utils';
import Link from 'next/link';
import { useRouter } from 'next/router';

import * as S from './Header.styles';

const Login = () => {
  const router = useRouter();
  const printiUrl = process.env.PRINTI_URL;
  const [isLogged, setIsLogged] = useState(!!localStorage.getItem('user'));
  const [userName, setUserName] = useState();
  const accountLink = isLogged ? `${printiUrl}/minha-conta` : '/login-cadastro';

  const fetchUser = useCallback(async () => {
    await api
      .get('/v2/customer/validate-auth')
      .then(({ data }) => {
        setIsLogged(true);
        setUserName(data.first_name);
        localStorage.setItem('user', JSON.stringify(data));
      })
      .catch(() => {
        localStorage.removeItem('user');
        setIsLogged(false);
      });
  }, []);

  useEffect(() => {
    if (localStorage && localStorage.getItem('user')) {
      const firstName = JSON.parse(localStorage.getItem('user')).first_name;
      setUserName(firstName);
    }

    fetchUser();
  }, [fetchUser, router.pathname]);

  return (
    <Link href={accountLink} as={accountLink} passHref>
      <S.LinkHeader className="header-my-account" data-optimum="header-my-account">
        {isLogged ? `Olá, ${userName}` : 'Minha conta'}
      </S.LinkHeader>
    </Link>
  );
};

export default memo(Login);
