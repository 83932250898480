/* eslint-disable react/no-danger */
import React, { memo, useState, useEffect, useCallback } from 'react';

import { Input, Text, Link as CompLink } from '@printi/printi-components';
import { useDebounce, formatMoney, cloudFront, uniqBy } from '@utils';
import Link from 'next/link';
import { useRouter } from 'next/router';

import * as S from './Header.styles';
import useSearchProductsCms from './useSearchProductsCMS';

const PrintiSearchCMS = () => {
  const router = useRouter();
  const [lastestSearches, setLastestSearches] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searched, setSearched] = useState(true);
  const debouncedIsSearchActive = useDebounce(isSearchActive, 100);
  const { searchProducts, products, crossSelling, loading } = useSearchProductsCms();

  useEffect(() => {
    if (localStorage) {
      setLastestSearches(() => JSON.parse(localStorage.getItem('printi-lastest-searches')));
    }
  }, []);

  const handleChangeSearchValue = useCallback((event) => {
    const { value } = event.currentTarget;
    setSearchValue(() => value);
  }, []);

  const handleSearchProducts = useCallback(
    (value) => {
      setIsSearchActive(true);
      searchProducts(value);
    },
    [searchProducts],
  );

  const hanadleClickProduct = useCallback(
    (event, product) => {
      event.preventDefault();

      if (lastestSearches) {
        lastestSearches.unshift(product);
        const searchesFiltered = uniqBy(lastestSearches, 'id').slice(0, 6);
        localStorage.setItem('printi-lastest-searches', JSON.stringify(searchesFiltered));
      } else {
        localStorage.setItem('printi-lastest-searches', JSON.stringify([product]));
      }

      setSearchValue(() => '');
      setIsSearchActive(false);
      router.push({
        pathname: `/${product.slug}`,
      });
    },
    [lastestSearches, router],
  );

  useEffect(() => {
    setSearched((v) => !v);
  }, [loading]);

  const handleGoSearchPage = useCallback(
    (event) => {
      event.preventDefault();
      const { key, type } = event;
      if (key === 'Enter') {
        searchProducts(searchValue);
      }

      if (key !== 'Enter' && type === 'click') {
        router.push({
          pathname: '/busca',
          query: { p: searchValue },
        });
        return true;
      }

      return true;
    },
    [router, searchProducts, searchValue],
  );
  return (
    <>
      <S.Overlay isActive={debouncedIsSearchActive} onClick={() => setIsSearchActive(false)} />
      <S.SearchProduct isActive={debouncedIsSearchActive}>
        <Input
          isBlock
          id="input-search"
          name="p"
          type="search"
          placeholder="Busque por um produto..."
          value={searchValue}
          onChange={handleChangeSearchValue}
          onKeyUp={handleGoSearchPage}
          onFocus={() => setIsSearchActive(true)}
          onBlur={() => setIsSearchActive(false)}
          autoComplete="off"
          removeErrorSpace
          rightElement={
            <S.SearchButton
              type="submit"
              onClick={() => handleSearchProducts(searchValue)}
              aria-label="Ir página de busca"
              data-optimum="input-search-button"
            >
              Buscar
            </S.SearchButton>
          }
        />

        {debouncedIsSearchActive && (lastestSearches || products) && (
          <S.SearchResult>
            {lastestSearches && lastestSearches.length > 0 && (!products || products.length === 0) && (
              <S.SideSearch>
                <Text size="lg" weight="bold" margin="0 0 .5rem">
                  Últimas pesquisas:
                </Text>

                {lastestSearches.map(({ id, title, slug }) => (
                  <Link key={id} href={`/${slug}`} as={`/${slug}`} passHref>
                    <CompLink kind="text" size="sm">
                      {title}
                    </CompLink>
                  </Link>
                ))}
              </S.SideSearch>
            )}

            {products && products.length > 0 ? (
              <S.MainResult>
                <Text size="lg" weight="bold">
                  Produtos encontrados:
                </Text>
                <S.ProductList>
                  {products.map(({ id, title, slug, minimum_price, image_small }) => (
                    <Link href={`/${slug}`} as={`/${slug}`} passHref legacyBehavior>
                      <S.Product
                        role="link"
                        data-optimum="search-product"
                        onClick={(e) => {
                          hanadleClickProduct(e, { id, title, slug });
                        }}
                      >
                        <S.ProductImage>
                          <img
                            key={id}
                            src={`${cloudFront}${image_small.file}`}
                            alt={image_small.alt || title}
                            width={140}
                            height={140}
                          />
                        </S.ProductImage>
                        <Text weight="bold" align="center">
                          {title}
                        </Text>
                        <Text size="xs" align="center">
                          {'A partir de '}
                          <Text as="span" size="sm" color="primary">
                            {formatMoney(minimum_price)}
                          </Text>
                        </Text>
                      </S.Product>
                    </Link>
                  ))}
                </S.ProductList>

                <CompLink size="sm" onClick={handleGoSearchPage}>
                  Encontrar mais produtos
                </CompLink>
              </S.MainResult>
            ) : (
              searched &&
              !loading && (
                <S.SearchResult className="aa-Source">
                  <S.MainResult>
                    <Text size="lg" weight="bold" margin="0 0 4rem">
                      Nenhum produto encontrado
                    </Text>
                  </S.MainResult>
                </S.SearchResult>
              )
            )}
            {products && products.length > 0 && crossSelling && crossSelling.length > 0 && (
              <S.SideSearch>
                <Text size="lg" weight="bold" margin="0 0 .5rem">
                  Relacionados:
                </Text>
                {crossSelling.map(({ id, title, slug }) => (
                  <CompLink
                    kind="text"
                    size="sm"
                    key={id}
                    href={`/${slug}`}
                    onClick={(e) => hanadleClickProduct(e, { id, title, slug })}
                  >
                    {title}
                  </CompLink>
                ))}
              </S.SideSearch>
            )}
          </S.SearchResult>
        )}
      </S.SearchProduct>
    </>
  );
};

export default memo(PrintiSearchCMS);
