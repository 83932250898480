/* eslint-disable react/no-danger */
import React, { memo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Logo, Icon, Row, Col } from '@printi/printi-components';
import { accountLoyaltyFetchRequest } from '@store/account/actions';
import { headerBannerLoadRequest } from '@store/headerBanner/actions';
import { searchStatusLoadRequest } from '@store/search/actions';
import { customEvents, useWindowSize } from '@utils';
import { useScrollDirection } from '@utils/useScrollDirection';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';

import IconWhatsapp from '../ButtonWhatsApp/icon';
import AlgoliaSearch from './Algolia/AlgoliaSearch';
import { EVENT_OPEN_SEARCH } from './Algolia/event-type';
import * as S from './Header.styles';
import TypesenseAutocomplete from './TypesenseAutocomplete';

const SearchProduct = dynamic(() => import('./SearchProduct'));
const PrintiSearchCMS = dynamic(() => import('./PrintiSearchCMS'));
const BottomHeader = dynamic(() => import('./BottomHeader'));
const Cart = dynamic(() => import('./Cart'));
const Login = dynamic(() => import('./Login'));

const whatsAppUrlSuporte = 'https://api.whatsapp.com/send?phone=551141183816';

const Header = () => {
  const mobileSize = 768;
  const router = useRouter();
  const dispatch = useDispatch();
  const { banner } = useSelector((state) => state.headerBanner);
  const { loyalty } = useSelector((state) => state.account);
  const [bannerType, setBannerType] = useState('desktop');
  const windowSize = useWindowSize();
  const { pathname } = router;
  const { loading: searchLoading, status, isLoaded: searchLoaded } = useSelector((state) => state.search);
  const { scrollDirection, pageYPostion } = useScrollDirection();
  const topHeaderRef = useRef();

  const handleCloseSearch = () => {
    customEvents.publish(EVENT_OPEN_SEARCH);
  };

  useEffect(() => {
    const { zanpid } = router.query;
    if (zanpid) {
      sessionStorage.setItem('zanpid', zanpid);
    }
  }, [router]);

  useEffect(() => {
    if (!loyalty.isRunning && !loyalty.isLoaded && (!loyalty || !loyalty.loyalty_tier_id)) {
      dispatch(accountLoyaltyFetchRequest());
    }
  }, [dispatch, loyalty]);

  useEffect(() => {
    if (!banner) {
      dispatch(headerBannerLoadRequest());
    }
  }, [banner, dispatch]);

  useEffect(() => {
    if (!searchLoading && !searchLoaded) {
      dispatch(searchStatusLoadRequest());
    }
  }, [searchLoading, searchLoaded, dispatch]);

  useEffect(() => {
    if (windowSize && windowSize.width < mobileSize) {
      setBannerType('mobile');
    }
  }, [windowSize]);

  const renderSearch = () => {
    const searchType = status?.type_search;
    let SearchComponent;

    switch (searchType) {
      case 'algolia':
        SearchComponent = AlgoliaSearch;
        break;
      case 'typesense':
        SearchComponent = TypesenseAutocomplete;
        break;
      case 'printi_bolt':
        SearchComponent = SearchProduct;
        break;
      default:
        // eslint-disable-next-line no-unused-vars
        SearchComponent = PrintiSearchCMS;
    }

    return SearchComponent ? <SearchComponent /> : null;
  };

  return (
    <S.Header top={topHeaderRef.current?.offsetHeight}>
      <S.SkipLink rel="nofollow" href="#main" onClick={() => document.querySelector('#main').focus()}>
        Ir para conteúdo principal
      </S.SkipLink>
      <S.SkipLink rel="nofollow" href="#footer" onClick={() => document.querySelector('#footer').focus()}>
        Ir para rodapé
      </S.SkipLink>
      <div ref={topHeaderRef}>
        {banner && <S.BannerHeader dangerouslySetInnerHTML={{ __html: banner[bannerType] }} />}

        <S.TopHeader>
          <Row isFull>
            <Col xs={12} noGutter>
              <S.LinkList>
                <S.LinksWrapper>
                  <S.LinkListRow last>
                    <Link href="tel:1141183820" as="tel:1141183820" passHref>
                      <S.TopHeaderLinks kind="text">
                        <Icon name="Phone" size="sm" />
                        Televendas (11) 4118-3820
                      </S.TopHeaderLinks>
                    </Link>
                  </S.LinkListRow>
                </S.LinksWrapper>
                <S.LinksWrapper>
                  <S.LinkListRow>
                    <S.TopLink target="_blank" href={whatsAppUrlSuporte} passHref>
                      <S.TopHeaderLinks kind="text" showIcon>
                        <IconWhatsapp size="14" />
                        (11) 4118-3816
                      </S.TopHeaderLinks>
                    </S.TopLink>
                  </S.LinkListRow>
                </S.LinksWrapper>
                <S.Divider />
                <S.LinksWrapper last>
                  <S.LinkListRow>
                    <Link href="/venda-corporativa" as="/venda-corporativa" passHref>
                      <S.TopHeaderLinks kind="text">
                        Venda Corporativa
                        <Icon name="Briefcase" size="sm" />
                      </S.TopHeaderLinks>
                    </Link>
                  </S.LinkListRow>
                  <S.LinkListRow>
                    <Link href="/indique-a-printi" as="/indique-a-printi" passHref>
                      <S.TopHeaderLinks kind="text">
                        Indique a Printi
                        <Icon name="Heart" size="sm" fill="#009BFF" />
                      </S.TopHeaderLinks>
                    </Link>
                  </S.LinkListRow>
                </S.LinksWrapper>
              </S.LinkList>
            </Col>
          </Row>
        </S.TopHeader>
      </div>
      <S.MainHeader>
        <Row middle="xs">
          <Col xs={4} sm={5} md={6} xl={2}>
            <Link href="/" as="" passHref>
              <S.LogoLink>
                {loyalty.loyalty_tier_id ? (
                  <img
                    src="https://printi-alpha-public-assets-us-east.s3.amazonaws.com/loyalty/9223372036854775807_PrintiMais.png"
                    alt="Printi Mais Logo"
                  />
                ) : (
                  <>
                    {pathname === '/' ? (
                      <S.H1TitleLogo>
                        <Logo id="Printi-Logo" size="md" />
                      </S.H1TitleLogo>
                    ) : (
                      <Logo id="Printi-Logo" size="md" />
                    )}
                  </>
                )}
              </S.LogoLink>
            </Link>
          </Col>

          <Col
            xs={12}
            xl={7}
            orders={[
              {
                mq: 'xs',
                order: 2,
              },
              {
                mq: 'xl',
                order: 0,
              },
            ]}
          >
            {searchLoaded && renderSearch()}
          </Col>

          <Col xs={8} sm={7} md={6} xl={3}>
            <S.MainHeaderRight>
              <Link href="/central-de-ajuda" as="/central-de-ajuda" passHref>
                <S.LinkHeader onFocus={handleCloseSearch}>Ajuda</S.LinkHeader>
              </Link>
              <Login />
              <Cart />
            </S.MainHeaderRight>
          </Col>
        </Row>
      </S.MainHeader>
      <BottomHeader show={scrollDirection === 'up' || pageYPostion === 0} />
    </S.Header>
  );
};

export default memo(Header);
