import React from 'react';

import IconWhatsApp from './icon';
import * as S from './styles';
import { useButtonWhatsApp } from './useButtonWhatsApp';

const whatsAppUrlSuporte = 'https://api.whatsapp.com/send?phone=551141183816';

export const ButtonWhatsApp = () => {
  const {
    isSettingPage,
    isShowButton,
    isShowMessage,
    hoverShowMessage,
    handleHiddenMessage,
    dataWhatsAppStatus,
    offsetHeightBanner,
  } = useButtonWhatsApp();

  return (
    <S.Container
      isSettingPage={isSettingPage}
      isShow={isShowButton}
      top={!offsetHeightBanner ? 110 : offsetHeightBanner + 10}
    >
      <S.Content>
        <S.Button
          target="_blank"
          href={whatsAppUrlSuporte}
          onMouseEnter={hoverShowMessage}
          onMouseLeave={handleHiddenMessage}
        >
          <IconWhatsApp />
        </S.Button>
        <S.Messages isShow={isShowMessage} className="message">
          {dataWhatsAppStatus.message}
        </S.Messages>
      </S.Content>
    </S.Container>
  );
};
