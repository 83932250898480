/* eslint-disable no-unused-vars */
import React, { memo, useState, useMemo, useCallback } from 'react';

import { Button, Row, Col, Text } from '@printi/printi-components';
import { customApi } from '@utils';

import * as S from './Footer.styles';
import IconNewsletter from './icons/IconNewsletter';
import NewsletterHubspot from './NewsletterHubspot';

const Newsletter = () => {
  const regex = useMemo(
    () =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    [],
  );
  const [email, setEmail] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleChangeEmail = useCallback((event) => {
    const { value } = event.currentTarget;
    setEmail(value);
  }, []);

  const handleRegisterEmail = useCallback(async () => {
    setEmailError('');
    setIsAlertOpen(false);
    if (!regex.test(email)) return setEmailError('Email incorreto');

    setEmailLoading(true);

    const payload = {
      email,
      component: 'footer',
      leadpage: document.title,
      leaduri: document.location.pathname,
      country_code: 'pt-BR',
      leadsource: 'newsletter',
      subscribed: true,
    };

    const dataLayer = {
      event: 'gaEvent',
      eventCategory: 'Newsletter Signup',
      eventLabel: '',
      eventValue: 0,
      eventNonInteraction: 0,
    };

    const errors = {
      CUSTOMER_DATA_ALREADY_EXISTS: 'Email já está cadastrado em nossa newsletter',
    };

    await customApi
      .post('/v1/newsletter', payload)
      .then(() => {
        window.dataLayer.push({
          ...dataLayer,
          eventAction: 'Success',
        });

        setIsAlertOpen(true);
      })
      .catch(({ response }) => {
        window.dataLayer.push({
          ...dataLayer,
          eventAction: 'Error',
        });

        if (response.data && response.data.message) {
          setEmailError(errors[response.data.message]);
        } else {
          setEmailError('ERROR_REGISTER_EMAIL');
        }
      });

    return setEmailLoading(false);
  }, [email, regex]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const { type } = event;

      if (type === 'submit' || type === 'click') {
        handleRegisterEmail();
      }
    },
    [handleRegisterEmail],
  );

  return (
    <S.Newsletter>
      <Row middle="xs">
        <Col xs={12} lg={6}>
          <S.NewsletterLeft>
            <S.IconNewsletter>
              <IconNewsletter />
            </S.IconNewsletter>

            <S.NewsletterInfo>
              <Text size="xlg" weight="bold">
                Inscreva-se em nossa newsletter
              </Text>
              <Text size="xs">para receber nossas últimas notícias e descontos exclusivos</Text>
            </S.NewsletterInfo>
          </S.NewsletterLeft>
        </Col>
        <Col xs={12} lg={6}>
          <NewsletterHubspot />
        </Col>
      </Row>
    </S.Newsletter>
  );
};

export default memo(Newsletter);
