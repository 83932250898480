import Typesense from 'typesense';

const apiKey = process.env.TYPESENSE_API_KEY || '';
export const searchClient = new Typesense.Client({
  nearestNode: { host: process.env.TYPESENSE_CLOUD_HOST || '', port: '443', protocol: 'https' },
  nodes: [
    { host: process.env.TYPESENSE_CLOUD_HOST || '', port: '443', protocol: 'https' },
    { host: process.env.TYPESENSE_CLOUD_HOST || '', port: '443', protocol: 'https' },
    { host: process.env.TYPESENSE_CLOUD_HOST || '', port: '443', protocol: 'https' },
  ],
  apiKey,
  connectionTimeoutSeconds: 2,
});
