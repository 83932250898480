/* eslint-disable jsx-a11y/iframe-has-title */
import React, { memo } from 'react';

import HubspotWrapper from '../Hubspot';
import BottomFooter from './BottomFooter';
import * as S from './Footer.styles';
import FooterLinks from './FooterLinks';
import Newsletter from './Newsletter';

const Footer = () => (
  <HubspotWrapper>
    <S.Footer id="footer">
      <Newsletter />
      <FooterLinks />
      <BottomFooter />
    </S.Footer>
  </HubspotWrapper>
);

export default memo(Footer);
