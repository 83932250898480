import React from 'react';

import { formatDate } from '@utils';

import { ARTICLES_INDEX_NAME } from '../constants';
import { searchClient } from '../searchClient';

export const articlesPlugin = {
  async getSources({ query }) {
    if (!query || query.length < 3) {
      return [];
    }
    const results = await searchClient.collections(ARTICLES_INDEX_NAME).documents().search({
      q: query,
      query_by: 'title,subtitle,aliases',
      filter_by: 'status:published',
      sort_by: 'sortable_id:ASC',
      // highlight_full_fields: 'title',
      // include_fields: 'query',
      per_page: 2,
    });

    return [
      {
        sourceId: 'articlesPlugin',
        getItems() {
          return results.hits;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
        templates: {
          header({ Fragment }) {
            return (
              <Fragment>
                <span className="aa-SourceHeaderTitle">Artigos</span>
              </Fragment>
            );
          },
          item({ item }) {
            return <ArticleItem hit={item} />;
          },
          footer({ state }) {
            return (
              state.context.nbArticles > 2 && (
                <div style={{ textAlign: 'center' }}>
                  <a
                    href="https://www.printi.com.br/blog"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="aa-SeeAllLink"
                  >
                    Ver todos os artigos
                  </a>
                </div>
              )
            );
          },
        },
      },
    ];
  },
};

function ArticleItem({ hit }) {
  const dt = new Date(hit.document?.publishedAt * 1000).toLocaleString();
  return (
    <a href="#" className="aa-ItemLink aa-ArticleItem">
      <div className="aa-ItemContent">
        <div className="aa-ItemPicture">
          <img src={hit.document?.image} alt={hit.document?.title} height="60" width="60" />
        </div>

        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">{hit.document?.title}</div>
          <div className="aa-ItemContentDate">Published on {formatDate(dt, { year: 'numeric', month: 'long' })}</div>
        </div>
      </div>
    </a>
  );
}
