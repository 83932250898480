import React, { memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Text } from '@printi/printi-components';
import { menuLinksLoadRequest } from '@store/menuLinks/actions';
import PropTypes from 'prop-types';

import * as S from './Sidebar.styles';

const Sidebar = ({ isSidebarOpen, openSidebar }) => {
  const dispatch = useDispatch();
  const { menuLinks } = useSelector((state) => state.menuLinks);

  const handleKeyUp = useCallback(
    (e) => {
      const keys = {
        27: () => {
          e.preventDefault();
          openSidebar(false);
        },
      };

      if (keys[e.keyCode] && isSidebarOpen) {
        keys[e.keyCode]();
      }
    },
    [openSidebar, isSidebarOpen],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  }, [handleKeyUp]);

  useEffect(() => {
    if (!menuLinks) {
      dispatch(menuLinksLoadRequest());
    }
  }, [menuLinks, dispatch]);

  const handleCloseUserSidebar = useCallback(
    (event) => {
      event.stopPropagation();
      openSidebar(false);
    },
    [openSidebar],
  );

  const handleFocusMenuHamburguer = ({ type, code }) => {
    if (type !== 'keyup') openSidebar(!isSidebarOpen);
    if (code === 'Tab') document.querySelector('#buttonCloseSideBar').focus();
    if (code === 'Enter' || code === 'Space') document.querySelector('#hamburguerIcon').focus();
  };

  return (
    <>
      <S.Background isOpen={isSidebarOpen} onClick={handleCloseUserSidebar} />
      <S.Sidebar isOpen={isSidebarOpen}>
        <S.Close
          id="buttonCloseSideBar"
          onClick={handleFocusMenuHamburguer}
          onKeyUp={handleFocusMenuHamburguer}
          aria-label="Fechar"
        >
          <Icon name="Close" fill="#fff" />
        </S.Close>
        {menuLinks &&
          menuLinks.map((menuLink) => (
            <div key={menuLink.id || menuLink.slug_id}>
              <Text color="inverse" weight="bold" size="lg" margin="0 0 .5rem">
                {menuLink.title}
              </Text>
              <S.NavMenu>
                {menuLink.sub_menu.map((link) => (
                  <S.MenuLink
                    key={link.id || link.slug_id}
                    kind="inverse"
                    href={link.menu_link}
                    target={link.target_link}
                  >
                    {link.title}
                  </S.MenuLink>
                ))}
              </S.NavMenu>
            </div>
          ))}
        <Text tabIndex="0" as="span" onKeyUp={handleFocusMenuHamburguer}></Text>
      </S.Sidebar>
    </>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  openSidebar: PropTypes.func.isRequired,
};

export default memo(Sidebar);
