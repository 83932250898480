import React, { createElement, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';

import { autocomplete } from '@algolia/autocomplete-js';
import { useRouter } from 'next/router';
import { pipe } from 'ramda';

import { createFillWith, uniqBy } from './functions';
import { articlesPlugin } from './plugins/articlesPlugin';
import { popularPlugin } from './plugins/popularPlugin';
import { productsPlugin } from './plugins/productsPlugin';
import { querySuggestionsPlugin } from './plugins/querySuggestionsPlugin';
import { quickAccessPlugin } from './plugins/quickAccessPlugin';
import * as S from './Typesense.styles';
import { cx, hasSourceActiveItem } from './utils';
import '@algolia/autocomplete-theme-classic';

const TypesenseAutocomplete = () => {
  const router = useRouter();
  const containerRef = useRef(null);
  const panelContainerRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const removeDuplicates = uniqBy(({ source, item }) => {
    const sourceIds = ['recentSearchesPlugin'];

    if (sourceIds.indexOf(source.sourceId) === -1) {
      return item;
    }
    return source.sourceId === 'querySuggestionsPlugin' ? item.query : item.label;
  });

  const fillWith = createFillWith({
    mainSourceId: 'querySuggestionsPlugin',
    limit: 10,
  });

  const handleSearchProduct = useCallback(
    (e) => {
      if (e.type === 'click' || e.key === 'Enter' || e.key === 'NumpadEnter') {
        router.push({
          pathname: '/busca',
          query: { p: searchTerm },
        });

        setIsActive(false);
      }
    },
    [router, searchTerm],
  );

  useEffect(() => {
    const input = document.querySelector('.aa-Input');

    input?.addEventListener('keydown', handleSearchProduct);

    return () => {
      input?.removeEventListener('keydown', handleSearchProduct);
    };
  }, [handleSearchProduct]);

  useEffect(() => {
    const combine = pipe(removeDuplicates, fillWith);

    const search = autocomplete({
      container: containerRef.current,
      panelPlacement: 'full-width',
      placeholder: 'Busque por um produto...',
      panelContainer: panelContainerRef.current,
      autoFocus: false,
      openOnFocus: true,
      debug: false,
      insights: false,
      detachedMediaQuery: 'none',
      plugins: [productsPlugin, articlesPlugin, querySuggestionsPlugin, popularPlugin, quickAccessPlugin],
      shouldPanelOpen({ state }) {
        return state;
      },
      renderer: { createElement, Fragment, render },
      reshape({ sourcesBySourceId, sources, state }) {
        const {
          recentSearchesPlugin: recentSearches,
          querySuggestionsPlugin: querySuggestions,
          categoriesPlugin: categories,
          popularPlugin: popular,
          popularCategoriesPlugin: popularCategories,
          ...rest
        } = sourcesBySourceId;

        const sourceIdsToExclude = ['popularPlugin', 'popularCategoriesPlugin'];
        const shouldDisplayPopularCategories = sources.every((source) => {
          if (sourceIdsToExclude.indexOf(source.sourceId) !== -1) {
            return true;
          }
          return source.getItems().length === 0;
        });

        return [
          combine(recentSearches, querySuggestions),
          [!state.query && popular, ...Object.values(rest), shouldDisplayPopularCategories && popularCategories].filter(
            Boolean,
          ),
        ];
      },
      render({ elements, state }, root) {
        const {
          recentSearchesPlugin: recentSearches,
          querySuggestionsPlugin: querySuggestions,
          categoriesPlugin: categories,
          productsPlugin: products,
          articlesPlugin: articles,
          popularPlugin: popular,
          quickAccessPlugin: quickAccess,
          popularCategoriesPluginv2: popularCategories,
        } = elements;

        const sourceIdsToExclude = ['popularPlugin', 'popularCategoriesPlugin'];
        const hasResults =
          state.collections
            .filter(({ source }) => sourceIdsToExclude.indexOf(source.sourceId) === -1)
            .reduce((prev, curr) => prev + curr.items.length, 0) > 0;
        setIsActive(state.isOpen);
        setSearchTerm(state.query);

        render(
          <S.TypesenseContainer>
            <div className="aa-PanelLayout aa-Panel--scrollable">
              {!hasResults && <div className="aa-NoResultsQuery">Nenhum resultado para `{state.query}`. </div>}
              <div className="aa-PanelSections">
                <div className="aa-PanelSection--left">
                  {hasResults ? (
                    (!state.query && recentSearches && (
                      <>
                        <div className="aa-SourceHeader">
                          <span className="aa-SourceHeaderTitle">Pesquisas recentes</span>
                        </div>
                        {recentSearches}
                      </>
                    )) ||
                    (state.query && (
                      <>
                        <div className="aa-SourceHeader">
                          <span className="aa-SourceHeaderTitle">Sugestões</span>
                        </div>

                        <div className="aa-PanelSectionSources">
                          {recentSearches}
                          {querySuggestions}
                          {categories}
                        </div>
                      </>
                    ))
                  ) : (
                    <div className="aa-NoResultsAdvices"></div>
                  )}
                  {!state.query && <div className="aa-PanelSection--popular">{popular}</div>}
                </div>
                <div className="aa-PanelSection--right">
                  {products && (
                    <div className="aa-PanelSection--products">
                      <div className="aa-PanelSectionSource">{products}</div>
                    </div>
                  )}
                  {articles && (
                    <div className="aa-PanelSection--articles">
                      <div className="aa-PanelSectionSource">{articles}</div>
                    </div>
                  )}
                  {quickAccess && (
                    <div
                      className={cx(
                        'aa-PanelSection--quickAccess aa-PanelSection--zoomable',
                        hasSourceActiveItem('quickAccessPlugin', state) && 'aa-PanelSection--active',
                      )}
                    >
                      {quickAccess}
                    </div>
                  )}

                  <div
                    className={cx(
                      'aa-PanelSection--popularCategories aa-PanelSection--zoomable aa-PanelSection--active',
                    )}
                  >
                    {popularCategories}
                  </div>
                </div>
              </div>
            </div>
          </S.TypesenseContainer>,
          root,
        );
      },
    });

    return () => {
      search.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Overlay isActive={isActive} onClick={() => setIsActive(false)} />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        @media only screen and (min-width: 1200px) {
          .aa-Panel {
            z-index: 10000;
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            max-width: 75rem !important;
            margin: 1rem auto !important;
          }
        }

        @media only screen and (max-width: 1200px) {
          .aa-Panel {
            z-index: 10000;
          }
        }
      `}</style>
      <S.SearchProduct tabindex="0" isActive={isActive} id="container_autocomplete">
        <S.CustomSearch>
          <div ref={containerRef} id="autocomplete" />
          <S.SearchButton
            type="submit"
            onClick={handleSearchProduct}
            onBlur={() => setIsActive(false)}
            aria-label="Ir página de busca"
            data-optimum="input-search-button"
            tabIndex="0"
          >
            Buscar
          </S.SearchButton>
        </S.CustomSearch>
        <div ref={panelContainerRef} id="panel-container" />
      </S.SearchProduct>
    </>
  );
};
export default TypesenseAutocomplete;
