import React, { memo, useState, useEffect, useRef } from 'react';

import { Icon, Text } from '@printi/printi-components';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import * as S from './Header.styles';
import { useProductMenu } from './useProductMenu';

const ProductMenu = ({ isActive, setIsProductMenuOpen, setIsModalOpenSales }) => {
  const {
    categories,
    indexCategory,
    handleSelectCategory,
    handleFocusFirstProduct,
    handleExitMenu,
    handleBackMenu,
    handleFocusFirstCategory,
    selectedCategory,
  } = useProductMenu({ setIsProductMenuOpen, isActive });

  const truncate = (input) => (input?.length > 26 ? `${input.substring(0, 26)}...` : input);
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
  const buttonRef = useRef(null);

  const handleModalIsOpen = () => {
    setIsModalOpenSales(true);

    setTimeout(() => {
      document.getElementById('myAnchor')?.focus();
    }, 100);
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleMouseMove = () => {
      setIsKeyboardNavigation(false);
    };

    const handleMouseStopMoving = () => {
      setIsKeyboardNavigation(true);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseenter', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseStopMoving);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseenter', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseStopMoving);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        setIsKeyboardNavigation(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (
      e.code === 'Enter' ||
      e.code === 'NumpadEnter' ||
      e.key === 'NumpadEnter' ||
      e.code === 'Space' ||
      e.key === 'Enter'
    )
      handleFocusFirstProduct(e);
  };

  return (
    <S.ProductMenu id="productMenuContainer" shadow isActive={isActive}>
      <S.Categories id="categoriesMenu" tabIndex="0">
        {categories &&
          categories.map(({ id, title }, index) => (
            <S.CategoryItem
              id={index === indexCategory ? 'active' : index}
              tabIndex="0"
              key={id}
              isActive={index === indexCategory}
              onKeyDown={handleKeyDown}
              onFocus={() => handleSelectCategory(index)}
              onMouseOver={() => handleSelectCategory(index)}
            >
              {title}
              <Icon name="ArrowIosForwardOutline" width="20" height="20" />
            </S.CategoryItem>
          ))}

        <S.JumpFocus as="button" role="button" tabIndex="0" onClick={handleExitMenu}>
          Fechar menu
        </S.JumpFocus>

        <S.JumpFocus as="span" onKeyUp={handleFocusFirstCategory}></S.JumpFocus>
      </S.Categories>
      <S.Products>
        {selectedCategory && (
          <>
            <Text style={{ fontSize: '25px' }} color="primary" weight="bold" margin="1rem 0 0.5rem 1rem">
              {selectedCategory.title}
            </Text>
            <S.ProductsList id="productsMenu">
              {selectedCategory.children.slice(0, 3).map(({ id, title, products }, index) => (
                <S.ProductsCategorySummary key={id}>
                  <S.ProductsListItemSummary>
                    <S.SubCategory key={id}>
                      <Text title="Categoria" size="sm" weight="bold" margin="0 0 0.5rem">
                        {title}
                      </Text>
                      <S.ProductsListSummary>
                        {products
                          .slice(0, 12)
                          .map(({ id: productId, slug, title: productTitle, badge_color, badge }) => (
                            <S.ProductsListItemSummary key={productId}>
                              <NextLink href={`/${slug}`} as={`/${slug}`} passHref>
                                <S.StyledLink
                                  kind="text"
                                  size="sm"
                                  color="normal"
                                  title={badge ? `${productTitle}, ${badge}` : `${productTitle}`}
                                  aria-label={productTitle}
                                >
                                  {productTitle?.length > 26 ? truncate(productTitle) : productTitle}
                                  {badge && badge_color ? (
                                    <S.TagBadge
                                      kind="filled"
                                      color={badge_color}
                                      aria-label={badge}
                                      margin="0 0 0.5rem"
                                    >
                                      {badge}
                                    </S.TagBadge>
                                  ) : null}
                                </S.StyledLink>
                              </NextLink>
                            </S.ProductsListItemSummary>
                          ))}
                        {(index === selectedCategory.children.length - 1 || index === 2) && (
                          <S.AccessibilityBtn style={{ display: isKeyboardNavigation ? 'block' : 'none' }}>
                            <button type="button" tabIndex="0" onClick={handleModalIsOpen}>
                              Falar com o televendas
                            </button>
                            <button
                              type="button"
                              tabIndex="0"
                              onClick={handleBackMenu}
                              ref={buttonRef}
                              onBlur={handleBackMenu}
                            >
                              Voltar ao menu
                            </button>
                          </S.AccessibilityBtn>
                        )}
                      </S.ProductsListSummary>
                    </S.SubCategory>
                  </S.ProductsListItemSummary>
                </S.ProductsCategorySummary>
              ))}
            </S.ProductsList>
          </>
        )}
      </S.Products>
    </S.ProductMenu>
  );
};

ProductMenu.propTypes = {
  isActive: PropTypes.bool.isRequired,
  setIsProductMenuOpen: PropTypes.func.isRequired,
  setIsModalOpenSales: PropTypes.func.isRequired,
};

export default memo(ProductMenu);
