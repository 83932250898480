import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Text } from '@printi/printi-components';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import * as S from './Header.styles';

export const ProductMenuSummaryComponent = ({
  isActive,
  selectedCategory,
  setIsActive,
  setIndexCategory,
  indexCategory,
  indexSelf,
  openModal,
  isKeyboardNavigation,
  setIsKeyboardNavigation,
}) => {
  const [position, setPosition] = useState();
  const refProduct = useRef();

  const isPartiallyOutOfViewport = useCallback((element) => {
    const rect = element.getBoundingClientRect();
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    const viewLeft = windowWidth - rect.left - rect.width;

    return { viewLeft, outViewPort: viewLeft < 0 };
  }, []);

  useEffect(() => {
    setPosition(isPartiallyOutOfViewport(refProduct.current));
  }, [isPartiallyOutOfViewport]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        setIsKeyboardNavigation(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setIsKeyboardNavigation]);

  const truncate = (input) => (input?.length > 26 ? `${input.substring(0, 26)}...` : input);

  const handleKeyUp = ({ target, code }) => {
    if (code === 'Enter') {
      const link = target.querySelectorAll('a');

      if (link.length > 0) link[0].focus();
    }
  };

  return (
    <S.ProductMenuSummary
      onKeyUp={({ code }) => {
        if (code === 'Escape') {
          setIsActive(false);
          setIndexCategory(null);
        }
      }}
      shadow
      isActive={isActive && indexCategory === indexSelf}
      ref={refProduct}
      left={position?.viewLeft}
      outViewPort={position?.outViewPort}
    >
      {selectedCategory
        .slice(0, 4)
        .map(({ id: childrenId, title: childrenTitle, products: childrenProducts }, index) => (
          <S.ProductsSummary
            key={childrenId}
            onKeyUp={handleKeyUp}
            title={childrenTitle}
            show={isActive && indexCategory === indexSelf}
          >
            <S.ProductsListSummary id="productsMenuSummary">
              <S.ProductsListItemSummary>
                <S.SubCategory key={childrenId}>
                  <Text weight="bold" margin="0 0 0.5rem" title="Categoria">
                    {childrenTitle}
                  </Text>
                  <S.ProductsListSummary id="productsSubemenuMenu">
                    {childrenProducts
                      .slice(0, 8)
                      .map(({ id: productId, slug, title: productTitle, badge_color, badge }) => (
                        <S.ProductsListItemSummary key={productId}>
                          <NextLink href={`/${slug}`} as={`/${slug}`} passHref>
                            <S.StyledLink
                              kind="text"
                              size="sm"
                              color="normal"
                              title={badge ? `${productTitle}, ${badge}` : productTitle}
                              aria-label={badge ? `${productTitle}, ${badge}` : productTitle}
                            >
                              {productTitle?.length > 26 ? truncate(productTitle) : productTitle}
                              {badge && badge_color ? (
                                <S.TagBadge kind="filled" color={badge_color} margin="0 0 0.5rem" aria-label={badge}>
                                  {badge}
                                </S.TagBadge>
                              ) : null}
                            </S.StyledLink>
                          </NextLink>
                        </S.ProductsListItemSummary>
                      ))}
                  </S.ProductsListSummary>
                  {(index === selectedCategory.length - 1 || index === 3) && (
                    <S.AccessibilityBtn style={{ display: isKeyboardNavigation ? 'block' : 'none' }}>
                      <button type="button" tabIndex="0" onClick={openModal}>
                        Falar com o televendas
                      </button>
                    </S.AccessibilityBtn>
                  )}
                </S.SubCategory>
              </S.ProductsListItemSummary>
            </S.ProductsListSummary>
          </S.ProductsSummary>
        ))}
    </S.ProductMenuSummary>
  );
};

ProductMenuSummaryComponent.propTypes = {
  setIsActive: PropTypes.func,
  setIndexCategory: PropTypes.func,
  setIsKeyboardNavigation: PropTypes.func,
  isKeyboardNavigation: PropTypes.bool,
  openModal: PropTypes.func,
  isActive: PropTypes.bool,
  selectedCategory: PropTypes.array,
  indexCategory: PropTypes.number,
  indexSelf: PropTypes.number,
};
