import { theme, Button } from '@printi/printi-components';
import styled from 'styled-components';
const { zindex, space } = theme;

export const Page = styled.section`
  background-color: #edf1f7;

  [class*='cookiePolicyText'] {
    padding-right: ${space.md};
  }
`;

export const ButtonPreviewMode = styled(Button)`
  padding: ${space.xs} ${space.xs};
  min-width: 0;
  position: fixed;
  bottom: 0;
  right: -${space.xl4};
  z-index: ${zindex.fixed};
  transition: 1s;

  &:hover {
    right: 0;
  }

  svg:last-of-type {
    margin-left: 0;
  }
`;
