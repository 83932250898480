import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { breakpoint } = theme;

export const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: ${(p) => (p.isSettingPage ? '150px' : `${p.top}px`)};
  z-index: 10;
  transition: bottom 500ms linear;
  visibility: ${(props) => (props.isShow ? 'visible' : 'hidden')};
  display: ${(props) => (props.isShow ? 'block' : 'none')};

  @media (max-width: ${breakpoint.sm}) {
    bottom: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
`;

export const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #25d366;
  height: 46px;
  width: 61.63px;

  border-radius: 0px 4px 4px 0px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

export const Messages = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 8px;

  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #2c2c3a;
  opacity: ${(props) => (props.isShow ? 1 : 0)};

  visibility: ${(props) => (props.isShow ? 'visible' : 'hidden')};
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  transition: visibility 300ms linear, opacity 300ms linear;

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    margin-left: 0px;
    top: 50%;
    transform: translateY(-50%);

    border: 8px solid #ffffff;
    border-color: transparent #ffffff transparent transparent;
  }
`;
