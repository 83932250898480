import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useSearchBox } from 'react-instantsearch';

import { Input } from '@printi/printi-components';
import { customEvents, useDebounce } from '@utils';
import { useRouter } from 'next/router';

import * as S from '../Header.styles';
import { EVENT_OPEN_SEARCH } from './event-type';

export function CustomSearchBox(props) {
  const router = useRouter();
  const {
    query: { p: queryValue },
    pathname,
  } = router;
  // eslint-disable-next-line react/prop-types
  const { setAutocompleteState, setQueryValue } = props;
  const { query, refine } = useSearchBox(props);
  const [searchValue, setSearchValue] = useState(queryValue);
  const inputRef = useRef(null);
  const debouncedValue = useDebounce(searchValue, 400);

  useEffect(() => {
    if (!inputRef.current?.isFocused() && query !== debouncedValue && query !== '') {
      setSearchValue(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleBlur = () => {
    if (searchValue === '') {
      customEvents.publish(EVENT_OPEN_SEARCH);
    }
  };

  const handleSearchProduct = useCallback(
    (e) => {
      const { key, type } = e;
      if (key === 'Enter' || type === 'click') {
        if (query !== searchValue && (query.length > 0 || searchValue.length > 0)) {
          refine(searchValue);
          setQueryValue(searchValue);
          if (pathname === '/busca') {
            router.push({
              pathname: '/busca',
              query: { p: searchValue },
            });
            return true;
          }
        }
      }

      return true;
    },
    [query, refine, router, searchValue],
  );
  const handleChange = useCallback((e) => {
    const { value } = e.currentTarget;
    setSearchValue(value);
  }, []);

  const onReset = useCallback(() => {
    setAutocompleteState(false);
  }, []);

  return (
    <Input
      ref={inputRef}
      isBlock
      id="input-search-page"
      type="search"
      autoComplete="off"
      removeErrorSpace
      placeholder="Busque por um produto..."
      value={searchValue}
      onSubmit={handleSearchProduct}
      onKeyPress={handleSearchProduct}
      onChange={handleChange}
      onFocus={() => setAutocompleteState(true)}
      onReset={onReset}
      rightElement={
        <S.SearchButton
          type="submit"
          onClick={handleSearchProduct}
          aria-label="Ir página de busca"
          data-optimum="input-search-button"
          onBlur={handleBlur}
        >
          Buscar
        </S.SearchButton>
      }
    />
  );
}

export default CustomSearchBox;
