import { theme, Button } from '@printi/printi-components';
import styled from 'styled-components';
const { borders, color, space, font } = theme;

export const PriceContent = styled.p`
  text-align: center;
`;
export const Partir = styled.p`
  margin: 0;
  padding: 0.5rem 0 0;
  color: rgb(21, 22, 23);
  text-align: center;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.33333;
`;

export const Price = styled.span`
  font-weight: bold;
  color: #009bff;
  margin: 0;
  padding: 0;
  text-align: unset;
  font-size: 1.125rem;
  line-height: 1.33333;
`;

export const MinimumQuantity = styled.span`
  margin: 0;
  padding: 0;
  color: rgb(21, 22, 23);
  text-align: unset;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.28571;
`;

export const Card = styled.a`
  display: inline-block;
  max-width: 21rem;
  width: 100%;
  border-radius: ${borders.radius.md};
  background-color: ${color.grayscale.white};
  overflow: hidden;
  transition: box-shadow 0.16s ease-in-out;

  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: 0 1px 5px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
  }

  &:active {
    background-color: ${color.grayscale.light};
  }

  picture,
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const CardImage = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
`;

export const CardInfos = styled.div``;

export const WrappTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${space.xl3};
`;

export const CardTitle = styled.p`
  display: -webkit-box;
  width: 100%;
  color: ${color.grayscale.black};
  font-size: ${font.size.h6};
  font-weight: ${font.weight.bold};
  line-height: ${font.lineHeight.h3};
  text-align: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

export const Header = styled.header`
  z-index: 10;
  margin-bottom: ${space.xl};
  position: sticky;
  top: -${(props) => props?.top}px;
`;
export const Overlay = styled.div`
  display: ${(p) => (p.isActive ? 'block' : 'none')};
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
`;

export const ProductList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Product = styled.a`
  position: relative;
  flex: 0 1 auto;
  width: 33.3%;
  padding: 1rem ${space.xs};
  text-align: center;
  cursor: pointer;
  transition: opacity 0.4s ease;

  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
`;

export const ProductImage = styled.div`
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0 ${space.sm};
  height: ${space.xl2};
`;
export const CategoryItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${space.xs};
  color: ${(p) => (p.isActive ? p.theme.color.primary.pure : p.theme.color.grayscale.black)};
  font-size: ${font.size.small};
  font-weight: ${font.weight.bold};
  cursor: pointer;

  &:hover {
    color: ${color.primary.pure};
  }
`;

export const Products = styled.div`
  width: calc(100% - 12rem);
  height: 30rem;
  margin: 0;
  padding: ${space.md};
  overflow-x: auto;
  overflow-y: hidden;

  p {
    white-space: nowrap;
  }
`;
