import React, { useState } from 'react';

import { formatMoney } from '@utils';

import { cx } from '../utils';
import * as S from './ProductItem.styles';

export function ProductItem({ hit }) {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <S.Card href={hit.document?.slug} rel="noreferrer noopener" className="aa-ItemLink aa-ProductItem">
        <S.CardImage className={cx('aa-ItemPicture', loaded && 'aa-ItemPicture--loaded')}>
          <img src={hit.document?.image} alt={hit.document?.name} onLoad={() => setLoaded(true)} />
        </S.CardImage>
        <S.CardInfos>
          <S.WrappTitle>
            <S.CardTitle>{hit.document?.name}</S.CardTitle>
          </S.WrappTitle>
          <S.Partir>a partir de</S.Partir>
          <S.PriceContent>
            <S.Price>{formatMoney(hit.document?.price)}</S.Price>
            <S.MinimumQuantity>/{hit.document?.minimum_quantity || 100} un</S.MinimumQuantity>
          </S.PriceContent>
        </S.CardInfos>
      </S.Card>
    </>
  );
}

export default ProductItem;
