import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cookieConsentPolicyLoadRequest } from '@store/cookieConsentPolicy/actions';
import { setCookie, uuidv4, customEvents, getCookie, isCookieConsentAvailable } from '@utils';
import PropTypes from 'prop-types';

import CookiePolicy from '../../contents/CookiePolicy';
import { EVENT_NAME } from '../ButtonWhatsApp/useButtonWhatsApp';
import * as S from './ModalCookieConsent.styles';
import useCookieConsent from './useCookieConsent';

const ModalCookieConsent = ({ close, hideBanner = false }) => {
  const cookieExpiryDays = 365;
  const dispatch = useDispatch();
  const { cookieConsentPolicy } = useSelector((state) => state.cookieConsentPolicy);
  const { storeCookieConsent } = useCookieConsent();

  const contentInitial = {
    cookieConsentText: '',
    cookiePolicyVersion: '',
    cookiePolicyDate: '',
    cookiePolicyText: '',
    isCookieContentValid: false,
  };

  const acceptedInitial = {
    alias: 'cct_accepted',
    name: 'is_accepted',
    value: true,
  };

  const informationInitial = {
    alias: 'cct_information',
    name: 'cct_information',
    value: {
      id: {
        alias: 'id',
        name: 'id',
        value: '',
        isRequired: true,
      },
      cpVersion: {
        alias: 'cp_version',
        name: 'cookie_policy_version',
        value: '',
        isRequired: true,
      },
      cpDate: {
        alias: 'cp_date',
        name: 'cookie_policy_date',
        value: '',
        isRequired: false,
      },
      prefFunctional: {
        alias: 'pref_functional',
        name: 'preferences_functional',
        value: !!getCookie('cct_information', true)?.pref_functional,
        isRequired: true,
      },
      prefAdvertising: {
        alias: 'pref_advertising',
        name: 'preferences_advertising',
        value: !!getCookie('cct_information', true)?.pref_advertising,
        isRequired: true,
      },
      prefAnalytical: {
        alias: 'pref_analytical',
        name: 'preferences_analytical',
        value: !!getCookie('cct_information', true)?.pref_analytical,
        isRequired: true,
      },
      origin: {
        alias: 'origin',
        name: 'origin',
        value: 'website',
        isRequired: false,
      },
      userAgent: {
        alias: 'ua',
        name: 'user_agent',
        value: navigator.userAgent,
        isRequired: false,
      },
    },
  };

  const [content, setContent] = useState(contentInitial);
  const [accepted, setAccepted] = useState(acceptedInitial);
  const [information, setInformation] = useState(informationInitial);
  const [showConsent, setShowConsent] = useState(isCookieConsentAvailable());
  const [processConsent, setProcessConsent] = useState(false);

  const getFormattedData = useCallback((obj, onlyRequired = false, itr = true, alias = false) => {
    const formattedData = {};

    if (!itr) {
      formattedData[alias ? obj.alias : obj.name] = obj.value;
      return formattedData;
    }

    Object.values(obj).forEach((item) => {
      if (onlyRequired && !item.isRequired) return;

      formattedData[alias ? item.alias : item.name] = item.value;
    });

    return formattedData;
  }, []);

  const prepareConsentPayload = useCallback(
    () => ({
      ...getFormattedData(information.value),
    }),
    [information, getFormattedData],
  );

  // Store cookie consent info in cookie
  const createCookies = useCallback(() => {
    const dataConsent = getFormattedData(information.value, true, true, true);

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'PageView');
      window.fbq('consent', 'grant');
    }

    if (typeof window.gtag === 'function') {
      window.gtag('consent', 'update', {
        ad_storage: dataConsent.pref_advertising ? 'granted' : 'denied',
        analytics_storage: dataConsent.pref_analytical ? 'granted' : 'denied',
        functionality_storage: dataConsent.pref_functional ? 'granted' : 'denied',
      });
    }

    setCookie(accepted.alias, accepted.value, cookieExpiryDays);
    setCookie(information.alias, dataConsent, cookieExpiryDays);
  }, [accepted, information, getFormattedData]);

  const generateCookieConsent = useCallback(() => {
    setProcessConsent(false);
    createCookies();
    storeCookieConsent(prepareConsentPayload());
    if (typeof close === 'function') close();
  }, [createCookies, storeCookieConsent, prepareConsentPayload, close]);

  const manageStatuses = useCallback((cookieContent, isAcceptedAll = false) => {
    customEvents.publish(EVENT_NAME);
    setShowConsent(false);
    setProcessConsent(true);
    setAccepted((prevState) => ({ ...prevState, value: true }));
    setInformation((prevState) => ({
      ...prevState,
      value: {
        ...prevState.value,
        id: {
          ...prevState.value.id,
          value: uuidv4(),
        },
        cpVersion: {
          ...prevState.value.cpVersion,
          value: cookieContent.cookiePolicyVersion,
        },
        cpDate: {
          ...prevState.value.cpDate,
          value: cookieContent.cookiePolicyDate,
        },
        prefFunctional: {
          ...prevState.value.prefFunctional,
          value: isAcceptedAll || prevState.value.prefFunctional.value,
        },
        prefAdvertising: {
          ...prevState.value.prefAdvertising,
          value: isAcceptedAll || prevState.value.prefAdvertising.value,
        },
        prefAnalytical: {
          ...prevState.value.prefAnalytical,
          value: isAcceptedAll || prevState.value.prefAnalytical.value,
        },
      },
    }));
  }, []);

  const handleSwitch = useCallback((name) => {
    setInformation((prevState) => ({
      ...prevState,
      value: {
        ...prevState.value,
        [name]: {
          ...prevState.value[name],
          value: !prevState.value[name]?.value,
        },
      },
    }));
  }, []);

  useEffect(() => {
    // If cookie content is set in bolt, then only proceed with cookie consent flow
    if (content.isCookieContentValid) {
      setShowConsent(hideBanner || isCookieConsentAvailable());
    }
  }, [content, hideBanner]);

  useEffect(() => {
    if (processConsent) generateCookieConsent();
  }, [processConsent, generateCookieConsent]);

  useEffect(() => {
    if (content.isCookieContentValid && showConsent) {
      customEvents.publish('show-modal-cookie', { detail: { show: true } });
    }
  }, [content.isCookieContentValid, showConsent]);

  useEffect(() => {
    if (cookieConsentPolicy) {
      const cookiePolicyContent = CookiePolicy(cookieConsentPolicy, handleSwitch, information);

      if (cookiePolicyContent.isCookieContentValid) {
        setContent((prevState) => ({
          ...prevState,
          ...cookiePolicyContent,
        }));
      }
      return;
    }

    if (!cookieConsentPolicy) dispatch(cookieConsentPolicyLoadRequest());
  }, [cookieConsentPolicy, dispatch, handleSwitch, information]);

  return (
    <>
      {content.isCookieContentValid && showConsent && (
        <S.StyledCookieConsent
          id="modal-cookie-consent"
          type="default"
          onSavePreferences={() => manageStatuses(content)}
          onAgreeAllCookies={() => manageStatuses(content, true)}
          cookieConsentText={content.cookieConsentText}
          cookiePolicyText={content.cookiePolicyText}
          hideBanner={hideBanner}
          showClose
          close={close}
        />
      )}
    </>
  );
};

ModalCookieConsent.propTypes = {
  hideBanner: PropTypes.bool,
  close: PropTypes.func,
};

export default ModalCookieConsent;
