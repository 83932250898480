import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { menuSummaryCategoriesLoadRequest } from '@store/menuSummaryCategories/actions';

export const useProductSummaryMenu = () => {
  const dispatch = useDispatch();
  const { summaryCategories } = useSelector((state) => state.menuSummaryCategories);

  useEffect(() => {
    if (!summaryCategories) {
      dispatch(menuSummaryCategoriesLoadRequest());
    }
  }, [summaryCategories, dispatch]);

  return {
    summaryCategories,
  };
};
