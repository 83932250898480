/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useEffect, useState } from 'react';
import { useHits } from 'react-instantsearch';

import recommend from '@algolia/recommend';
import { RelatedProducts } from '@algolia/recommend-react';
import { Text, Link as CompLink } from '@printi/printi-components';
import { formatMoney } from '@utils';
import Link from 'next/link';
import { useRouter } from 'next/router';

import * as S from '../Header.styles';

const appId = process.env.ALGOLIA_APP_ID;
const algoliaIndexKey = process.env.ALGOLIA_INDEX_KEY;
const apiRecommendKey = process.env.ALGOLIA_API_RECOMMEND__KEY;

const recommendClient = recommend(appId, apiRecommendKey);

export function CustomHits(props) {
  const router = useRouter();
  const { hits, results } = useHits(props);
  const [referenceHit, setReferenceHit] = useState(null);
  // eslint-disable-next-line react/prop-types
  const { isActive, searchValue, handleOpenSearch } = props;

  useEffect(() => {
    if (hits.length && referenceHit !== null) {
      setReferenceHit(null);
    }
  }, [hits, referenceHit]);

  const handleGoSearchPage = useCallback(
    (event) => {
      event.preventDefault();
      const { key, type } = event;

      if (key === 'Enter' || type === 'click') {
        handleOpenSearch();
        router.push({
          pathname: '/busca',
          query: { p: searchValue },
        });

        return true;
      }

      return true;
    },
    [router, searchValue],
  );

  const hitsTemplate = () => (
    <>
      {hits.map((hit, index) => {
        if (index === 0 && hit.objectID !== referenceHit) {
          setReferenceHit(hit.objectID);
        }

        return (
          <Link key={hit.id} href={`/${hit.slug}`} passHref legacyBehavior>
            <S.Product
              key={hit.objectID}
              data-optimum="searched-product"
              data-insights-object-id={hit.objectID}
              data-insights-position={hit.__position}
              data-insights-query-id={hit.__queryID}
              onClick={handleOpenSearch}
            >
              <S.ProductImage>
                <img key={hit.id} src={hit.image} alt={hit.name} width={140} height={140} />
              </S.ProductImage>
              <Text weight="bold" align="center">
                {hit.name}
              </Text>
              <Text size="xs" align="center">
                {'A partir de '}
                <Text as="span" size="sm" color="primary">
                  {formatMoney(hit.price)}
                </Text>
              </Text>
            </S.Product>
          </Link>
        );
      })}
    </>
  );

  function HeaderComponent() {
    return (
      <Text size="lg" weight="bold" margin="0 0 .5rem">
        Relacionados:
      </Text>
    );
  }

  // eslint-disable-next-line react/prop-types
  function RelatedItem({ item: { id, name, slug } }) {
    return (
      <CompLink kind="text" size="sm" key={id} href={`/${slug}`}>
        {name}
      </CompLink>
    );
  }

  // eslint-disable-next-line no-shadow
  function ListView(props) {
    return (
      <S.ListView>
        {/* eslint-disable-next-line react/prop-types */}
        {props.items.map((item) => (
          <props.itemComponent item={item} />
        ))}
      </S.ListView>
    );
  }

  const relatedProductsTemplate = () => (
    <>
      {referenceHit && (
        <S.SideSearch>
          <RelatedProducts
            /* eslint-disable-next-line react/jsx-no-bind */
            headerComponent={HeaderComponent}
            recommendClient={recommendClient}
            indexName={algoliaIndexKey}
            objectIDs={[referenceHit]}
            /* eslint-disable-next-line react/jsx-no-bind */
            view={ListView}
            /* eslint-disable-next-line react/jsx-no-bind */
            itemComponent={RelatedItem}
            maxRecommendations={12}
          />
        </S.SideSearch>
      )}
    </>
  );

  return (
    <>
      {isActive && results.nbHits > 0 && searchValue && (
        <S.SearchResult className="aa-Source">
          <S.MainResult>
            <Text size="lg" weight="bold">
              Produtos encontrados:
            </Text>
            <S.ProductList>{hitsTemplate()}</S.ProductList>
            <CompLink size="sm" onClick={handleGoSearchPage}>
              Encontrar mais produtos
            </CompLink>
          </S.MainResult>
          {results.nbHits > 0 && relatedProductsTemplate()}
        </S.SearchResult>
      )}

      {isActive && !results.nbHits && searchValue && (
        <S.SearchResult className="aa-Source">
          <S.MainResult>
            <Text size="lg" weight="bold" margin="0 0 4rem">
              Nenhum produto encontrado
            </Text>
          </S.MainResult>
        </S.SearchResult>
      )}
    </>
  );
}

export default CustomHits;
